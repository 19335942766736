import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";

const PAUSA = 300;

@Component({
    selector: 'layout-v2-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit{

    @Input()
    public type: string = "text";

    @Input()
    public label: string = "";

    @Input()
    public placeholder: string = "";

    @Output()
    public typed: EventEmitter<string> = new EventEmitter<string>();

    public campoInput = new FormControl();

    constructor(){}

    ngOnInit(): void {
        this.campoInput.valueChanges
            .pipe(debounceTime(PAUSA))
            .subscribe({
            next: valor => {
                this.typed.emit(valor);
            }
        });
    }

}
