import { Injectable, HostListener } from '@angular/core';

@Injectable()
export class ExitIntentService {

    constructor() {
        this.adicionarListener();
    }

    @HostListener('window:beforeunload', ['$event'])
    onBeforeUnload(event: BeforeUnloadEvent) {
        event.preventDefault();
        event.returnValue = ''; // necessário para que a mensagem de confirmação padrão do navegador seja exibida
    }

    public adicionarListener() {
        window.addEventListener('beforeunload', this.onBeforeUnload);
    }

    public removerListener() {
        console.log('removendo listener');
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

}
