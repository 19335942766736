import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { Marcacao } from "../models/marcacao.model";
import { IdSessaoService } from "./id-sessao.service";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Injectable()
export class MarcacaoService{

    constructor(
        private idSessaoService: IdSessaoService,
        private localStorageService: LocalStorageService
    ){}

    public isMarcacaoPresente(nomeForm:string, pergunta: Pergunta): string | null{
        if(pergunta.resposta.tipoResposta != TipoResposta.MARCACAO_USUARIO) return null;
        let marcacao = this.getPartesMarcacao(
            this.localStorageService.obterItem(this.getNomeMarcacao(nomeForm,pergunta.identificador)));
        if(!marcacao) return null;
        if (!marcacao.link) return null;
        return marcacao.link;
    }

    public isPerguntaMarcacao(nomeForm:string,resposta: RespostaUsuario): string | null{
        if(resposta.pergunta.resposta.tipoResposta != TipoResposta.MARCACAO_USUARIO) return null;
        let resp = resposta.resposta as Alternativa;
        this.localStorageService.adicionarItem(
            this.getNomeMarcacao(nomeForm,resposta.pergunta.identificador),
            this.getMarcacao(resp.titulo,resp.redirecionamentoLink as string)
        );
        if (!resp.redirecionamentoLink) return null;
        return resp.redirecionamentoLink;
    }

    private getNomeMarcacao(nomeForm:string, identificador:string): string{
        return `${nomeForm}-${identificador}-marcacao`;
    }

    private getMarcacao(titulo:string, redirecionamentoLink: string):string{
        return `${this.idSessaoService.recuperarIdSessao()};${titulo};${redirecionamentoLink}`;
    }

    private getPartesMarcacao(marcacao: string | null): Marcacao | null{
        if(!marcacao) return null;
        let marcacaoPartes = (marcacao as string).split(";") as string[];
        return {
            id: marcacaoPartes[0] as unknown as number,
            valor: marcacaoPartes[1],
            link: marcacaoPartes[2] == 'null' ? null : marcacaoPartes[2]
        } as Marcacao;
    }


}
