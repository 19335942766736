import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ObservablesService {

    private dataSubject = new Subject<void>();

    data$ = this.dataSubject.asObservable();

    updateData() {
        this.dataSubject.next();
    }

}
