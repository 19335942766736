<layout-v2-title [title]="pergunta.titulo" [idPergunta]="pergunta.id"></layout-v2-title>

<layout-v2-description [description]="pergunta.descricao" [idPergunta]="pergunta.id"></layout-v2-description>

<layout-v2-video-personalizado [id]="pergunta.id" [heightBoxVideo]="pergunta.alturaBoxVideo"
    [link]="pergunta.resposta.linkVideo"
    [videoID]="pergunta.resposta.linkVideo"
    [margimTopBoxVideo]="pergunta.margemTopBoxVideo"
    [foiParaProximaPergunta]="foiPraProximaPergunta"
    (eventChegouFinal)="onEventChegouFinalVideo()" (eventDeuPlay)="onEventDeuPlayVideo()" 
    (eventTempoAsssitido)="onEventTempoAssistido($event)" ></layout-v2-video-personalizado>

<layout-v2-button type="2" *ngIf="pergunta.exibirBtnWhatsApp" (clicked)="onAnswered()"></layout-v2-button>

<ng-container *ngIf="!pergunta.exibirBtnWhatsApp">

    <ng-container *ngIf="pergunta.personalizarBtnAvancar">
        <layout-v2-button type="3"
            [id]="pergunta.botaoAvancar.id"
            [showLetter]="false"
            [ownStyle]="true"
            [styleButton]="pergunta.botaoAvancar"
            [title]="pergunta.botaoAvancar.textoBotaoPersonalizado ? pergunta.botaoAvancar.textoBotao : 'Continuar !'"
            (clicked)="onAnswered()"></layout-v2-button>
    </ng-container>

    <ng-container *ngIf="!pergunta.personalizarBtnAvancar">
        <layout-v2-button type="3" title="Continuar" (clicked)="onAnswered()"></layout-v2-button>
    </ng-container>

</ng-container>

<layout-v2-button *ngIf="enableBackButton" type="1" (clicked)="onBack()"></layout-v2-button>