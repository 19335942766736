import { Component, Input, AfterContentChecked, AfterViewChecked, OnInit, EventEmitter, Output, OnChanges, SimpleChanges  } from "@angular/core";

@Component({
    selector: 'layout-v2-video-personalizado',
    templateUrl: './video-personalizado.component.html',
    styleUrls: ['./video-personalizado.component.scss']
})
export class VideoPersonalizadoComponent implements OnChanges, AfterViewChecked {
    
    @Input()
    public id!: number;

    @Input()
    public link!:string | null | undefined;

    @Input()
    public videoID: string | null | undefined = ""; 

    @Input()
    public heightBoxVideo: number | undefined = 0;

    @Input()
    public margimTopBoxVideo: number | undefined = 0

    @Input()
    public foiParaProximaPergunta: boolean = false

    public tempoAtual = "00:00";
    public tempoTotal = "00:00";

    public video!: HTMLMediaElement;

    private svgPlay = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>`;
    private svgPause = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>`;

    @Output()
    public eventChegouFinal:EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public eventDeuPlay:EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public eventTempoAsssitido: EventEmitter<number> = new EventEmitter<number>();

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['foiParaProximaPergunta'] && this.video) {
            this.eventTempoAsssitido.emit(this.video.currentTime);
            this.video.pause();
        }
    }

    ngAfterViewChecked(): void {
        this.video = document.querySelector(`#${this.getIdVideo(this.id)}`) as HTMLMediaElement;
    }

    public getIdVideo(id: number | null | undefined): string {
        let idCompleto = '';
        if (id != null) {
            idCompleto = "ID_VIDEO_" + id;
        }
        return idCompleto;
    }

    public getIdVideoBox(id: number | null | undefined) {
        return this.getIdVideo(id) + "_box";
    }

    public getIdBarra(id: number | null | undefined) {
        return this.getIdVideo(id) + "_barra";
    }

    public getIdBtnMinimizar(id: number | null | undefined) {
        return this.getIdVideo(id) + "_btnMinimizar";
    }

    public getEstiloVideo(): string {
        let altura = this.heightBoxVideo == null ? '320px' : `${this.heightBoxVideo}px`;
        let margem = this.margimTopBoxVideo == null ? '0px' : `${this.margimTopBoxVideo}px`;
        let estilo = `position: relative; width: 100%; max-height: 220px; height: ${altura} !important; margin-top: ${margem} !important;`;
        return estilo;
    }

    public playPause(button: HTMLButtonElement){
        if(this.video.paused)  { 
            button.innerHTML = "";
            button.innerHTML = this.svgPause;
            this.video.play();
            this.eventDeuPlay.emit();
        }else{
            button.innerHTML = "";
            button.innerHTML = this.svgPlay;
            this.video.pause();
        }
    }

    public contadorTempo(event: any) {
        if(this.video){
            let barra = document.querySelector(`#${this.getIdBarra(this.id)}`) as HTMLDivElement;
            this.tempoAtual = this.setTempo(this.video.currentTime);
            this.tempoTotal = this.setTempo(this.video.duration);
            if(barra && this.video.duration != 0 && this.video.currentTime != 0) {
                let porc = (this.video.currentTime / this.video.duration) * 100 ;
                barra.style.width = `${porc}%`;
            }
        }
    }

    private setTempo(seconds: number): string{
        seconds = !seconds ? 0 : seconds;
        let mins = Math.floor(seconds / 60);
        let secs = Math.floor(seconds % 60);
        return `${mins < 10 ? "0" + String(mins) : mins}:${secs < 10 ? "0" + String(secs) : secs}`;
    }

    public maximizar(button: HTMLButtonElement){
        //let btnMinimizar = document.querySelector(`#${this.getIdBtnMinimizar(this.id)}`) as HTMLButtonElement;
        this.video.requestFullscreen();
        //btnMinimizar.requestFullscreen();
    }

    public getSource(chave: string | null | undefined):string{
        return `https://prevnator-images.s3.sa-east-1.amazonaws.com/${chave}`;
    }

    public getChaveVideo(id: number):string{
        return `video_${id}`;
    }

    public chegouFinal(){
        this.eventChegouFinal.emit();
        this.eventTempoAsssitido.emit(this.video.duration);
    }

}