import { Component, Input } from "@angular/core";
import { VideoService } from "./video.service";
import { YoutubePlayerService } from "../youtube-player/youtube-player-service";

@Component({
    selector: 'layout-v2-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent {

    @Input()
    public id!: number;

    @Input()
    public gifVideo: string | null | undefined = "";

    @Input()
    public linkVideo: string | null | undefined = "";

    @Input()
    public fullScreen: boolean = false;

    @Input()
    public timeFullScreen: number = 0;

    @Input()
    public heightBoxVideo: number | undefined = 0;

    @Input()
    public margimTopBoxVideo: number | undefined = 0

    private videoPlaying: boolean = false;

    private intervaloExitFullScreen: any;

    constructor(
        private videoService: VideoService,
        private youtubePlayerService: YoutubePlayerService
    ) { }

    public getIdVideo(id: number | null | undefined): string {
        let idCompleto = '';
        if (id != null) {
            idCompleto = "ID_VIDEO_" + id;
        }

        this.videoService.addVideo(idCompleto);
        return idCompleto;
    }

    public getIdVideoBox(id: number | null | undefined) {
        return this.getIdVideo(id) + "_box";
    }

    public getLarguraVideo(id: number | null | undefined) {
        let idCompleto = this.getIdVideoBox(id);
        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;
        const estiloComputado = window.getComputedStyle(elementoPai);
        let largura = parseInt(estiloComputado.width, 10);
        return largura;
    }

    public getAlturaVideo(id: number | null | undefined) {
        let idCompleto = this.getIdVideoBox(id);
        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;
        const estiloComputado = window.getComputedStyle(elementoPai);
        let altura = parseInt(estiloComputado.height, 10);
        return altura;
    }

    public playVideoGif(id: number | null | undefined) {

        let idCompleto = this.getIdVideo(id);
        let idIframe = '';

        let videoPlayer = undefined;

        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;

        for (let i = 0; i < elementoPai.children.length; i++) {
            const elementoFilho = elementoPai.children[i];
            if (elementoFilho.tagName === 'IFRAME') {
                const elementoFilho = elementoPai.children[i];
                videoPlayer = elementoFilho as HTMLElement;
                videoPlayer.style.width = elementoPai.style.width;
                videoPlayer.style.height = elementoPai.style.height;
                idIframe = elementoFilho.id;

                break;
            }
        }

        this.videoService.setVideoPlay(idCompleto);

        if (videoPlayer && !this.videoPlaying) {
            if (this.fullScreen) {
                this.iniciarTelaInteira(videoPlayer, this.timeFullScreen);
            }
            this.youtubePlayerService.play();

        } else {
            this.youtubePlayerService.pause();
        }

        this.videoPlaying = !this.videoPlaying;

    }

    private iniciarTelaInteira(videoElement: HTMLElement, tempo: number) {
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        }
        if (tempo == undefined) {
            let tempoTotal = this.youtubePlayerService.getTempoTotal();
            if (tempoTotal) {
                tempo = tempoTotal;
            }
        }

        if (tempo != undefined) {
            this.intervaloExitFullScreen = setInterval(() => {
                let tempoAtual = this.youtubePlayerService.getTempoAtual();
                if (tempoAtual) {
                    if (tempoAtual >= tempo) {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                            clearInterval(this.intervaloExitFullScreen);
                        }
                    }
                }
            }, 2000);
        }

    }


    showGifOverlay(): boolean {
        return !this.videoPlaying && this.gifVideo != undefined && this.gifVideo != '';
    }

    public getEstiloVideo(): string {
        let altura = this.heightBoxVideo == null ? '320px' : `${this.heightBoxVideo}px`;
        let margem = this.margimTopBoxVideo == null ? '0px' : `${this.margimTopBoxVideo}px`;
        let estilo = `position: relative; width: 100%; max-height: 220px; height: ${altura} !important; margin-top: ${margem} !important;`;
        return estilo;
    }

    public getLinkVideo(): string {
        return this.linkVideo as string;
    }

}
