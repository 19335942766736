<p *ngIf="description != null" [id]="getIdDescription()" class="layout-v2-p"></p>

<ng-container *ngIf="list.length > 0">
    <ul class="personal-list">
        <li *ngFor="let item of list">
            <div class="bullet">
                <svg style="margin-top: 3px;" width="30px" height="30px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#00C29E" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g><g id="SVGRepo_iconCarrier"> <path d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z" fill="#00C29E"></path></g></svg>
            </div>
            <div class="texto">
                {{item}}
            </div>
        </li>
    </ul>
</ng-container>
