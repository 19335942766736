import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Alternativa } from "src/app/models/alternativa.model";
import { EstiloFormulario } from "src/app/models/estilo-formulario.model";
import { Formulario } from "src/app/models/formulario.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { PassadorService } from "src/app/view/services/passador.service";

@Component({
    selector: 'layout-v1-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnChanges{

    @Input()
    public formulario!: Formulario;

    @Input()
    public perguntas!: Pergunta[];

    @Output()
    public eventoVoltar:EventEmitter<void> = new EventEmitter();

    @Output()
    public eventoResponder:EventEmitter<RespostaUsuario> = new EventEmitter();

    private VALOR_MAGICO: number = 85;
    private passos: number = 0;
    private margemPartida: any[] = [];

    private passarPergunta: boolean = true;

    constructor(
        private passadorService: PassadorService
    ){
        this.margemPartida.push("0px");
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(this.formulario){
            this.cssPersonalizado(this.formulario.estilo);
        }
        this.passadorService.getPassador().subscribe({
            next: valor => this.passarPergunta = valor
        })
    }

    public onResponder(resposta: RespostaUsuario){

        this.eventoResponder.emit(resposta);

        let pergunta = resposta.pergunta as Pergunta;

        if(!pergunta.ultima && (pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA
            || pergunta.resposta.tipoResposta == TipoResposta.MARCACAO_USUARIO)) {

            let resp = resposta.resposta as Alternativa;

            if(!resp.ultima && resp.redirecionamentoPergunta && this.passarPergunta == true) this.proximaPergunta();

        } else if(!pergunta.ultima && this.passarPergunta == true) {
            this.proximaPergunta();
        }

    }

    private proximaPergunta() {
        const slide = document.querySelector(".slide") as HTMLDivElement;
        this.margemPartida.push(slide.style.marginTop);
        this.passos++;
        let valorDeslocamento = this.passos * this.VALOR_MAGICO;
        slide.style.marginTop = `-${valorDeslocamento}vh`;
    }

    public onVoltar(){
        this.passos--;
        const slide = document.querySelector(".slide") as HTMLDivElement;
        slide.style.marginTop = this.margemPartida.pop();
        this.eventoVoltar.emit();
    }

    private cssPersonalizado(estilo: EstiloFormulario | undefined){
        if(!estilo) return;
        let div = document.querySelector("#container") as HTMLDivElement;

        if(estilo.exibeImagemFundo){

            div.style.backgroundImage = `url('data:image/jpg;base64,${estilo.imagemFundoBase64}')`;
            if(estilo.posicaoImagem == "Preencher"){
                div.style.backgroundRepeat = "no-repeat, no-repeat";
                div.style.backgroundSize = "cover";
            }
            if(estilo.posicaoImagem = "Centralizar"){
                div.style.backgroundRepeat = "no-repeat, no-repeat";
                div.style.backgroundPosition = "center";
            }

        }else{
            div.style.backgroundColor = estilo.corFundo;
        }

    }

}
