import { Component, Input } from "@angular/core";
import { FormularioOpcao } from "src/app/models/formulario-opcao.model";

@Component({
    selector: 'layout-v2-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent{

    @Input()
    public estilo!: FormularioOpcao | undefined;

    constructor(){}

}
