import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v2-assembler-information-text',
    templateUrl: './assembler-information-text.component.html',
    styleUrls: ['./assembler-information-text.component.scss']
})
export class AssemblerInformationTextComponent{

    @Input()
    public pergunta!: Pergunta;

    @Input()
    public enableBackButton: boolean = true;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    constructor(){}

    public onAnswered(){
        let resposta = {
            pergunta: this.pergunta,
            resposta: ""
        } as RespostaUsuario;
        this.answered.emit(resposta);
    }

    public onBack(){
        this.back.emit();
    }

}
