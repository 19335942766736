import { NgModule } from "@angular/core";
import { CampoFormularioComponent } from "./campo-formulario.component";
import { CommonModule } from "@angular/common";
import { YouTubePlayer, YouTubePlayerModule } from "@angular/youtube-player";
import { YoutubePlayerLayoutv1Component } from "./youtube-player/youtube-player.component";
import { YoutubePlayerService } from "./youtube-player/youtube-player-service";
import { CampoHtmlComponent } from "./html/campo-html.componente";
import { CampoFormularioService } from "./campo-formulario.service";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
    declarations: [
        CampoFormularioComponent,
        YoutubePlayerLayoutv1Component,
        CampoHtmlComponent
    ],
    imports: [
        CommonModule,
        YouTubePlayerModule,
        NgxMaskModule.forChild()
    ],
    exports: [
        CampoFormularioComponent
    ],
    providers: [
        YouTubePlayer,
        YoutubePlayerService,
        CampoFormularioService
    ]
})
export class CampoFormularioModule{

}
