import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BarraProgressoComponent } from "./barra-progresso.component";

@NgModule({
    declarations: [
        BarraProgressoComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BarraProgressoComponent
    ],
    providers: []
})
export class BarraProgressoModule{

}
