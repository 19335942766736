import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Formulario } from "src/app/models/formulario.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v1',
    templateUrl: './layout-v1.component.html',
    styleUrls: ['./layout-v1.component.scss']
})
export class LayoutV1Componet{

    @Input()
    public formulario!: Formulario;

    @Input()
    public perguntas!: Pergunta[];

    @Output()
    public eventoResponder:EventEmitter<RespostaUsuario> = new EventEmitter();

    @Output()
    public eventoVoltar:EventEmitter<void> = new EventEmitter();

    constructor(){}

    public onResponder(resposta: RespostaUsuario){
        this.eventoResponder.emit(resposta);
    }

    public onVoltar(){
        this.eventoVoltar.emit();
    }

}
