import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ControllerModule } from './controller/controller.module';
import { SpinnerModule } from './view/components/spinner/spinner.module';
import { NgxMaskModule } from 'ngx-mask';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ControllerModule,
        SpinnerModule,
        NgxMaskModule.forRoot(),
        NoopAnimationsModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
