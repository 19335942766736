import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { TipoResposta } from "src/app/models/tipo-resposta.model";

@Component({
    selector: 'layout-v2-assembler-information-video-personalizado',
    templateUrl: './assembler-information-video-personalizado.component.html',
    styleUrls: ['./assembler-information-video-personalizado.scss']
})
export class AssemblerInformationVideoPersonalizadoComponent{

    @Input()
    public pergunta!: Pergunta;

    @Input()
    public enableBackButton: boolean = true;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public eventVideo:EventEmitter<any> = new EventEmitter<any>();

    public foiPraProximaPergunta: boolean = false; 

    constructor(){}

    public onAnswered(){

        let resposta = {
            pergunta: this.pergunta,
            resposta: ""
        } as RespostaUsuario;
        
        if(this.pergunta.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO) {
           
            this.foiPraProximaPergunta = !this.foiPraProximaPergunta; //O VALOR NÃO IMPORTA, O QUE VALE É A MUDANÇA DO VALOR
           
            setTimeout(() => { //PEQUENO ATRASO DE 2ms PARA GARANTIR A CAPTURA DO TEMPO ASSISTIDO DO VIDEO
                this.answered.emit(resposta);
            },200)

        }else{

            this.answered.emit(resposta);
            
        }
        
    }

    public onBack(){
        this.back.emit();
    }

    public onEventChegouFinalVideo(){ 
        let dado = ['assistiuVideo', true];
        this.eventVideo.emit(dado);
    }

    public onEventDeuPlayVideo(){
        let dado = ['deuPlayVideo', true];
        this.eventVideo.emit(dado); 
    }

    public onEventTempoAssistido(tempo: number) {
        let dado = ['tempoAssistidoVideo', tempo];
        this.eventVideo.emit(dado);
    }

}