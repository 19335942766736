import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ComponentsModule } from "../components/components.module";
import { AssemblerComponent } from "./assembler.component";
import { AssemblerUnicTypeComponent } from "./assembler-unic-type/assembler-unic-type.component";
import { AssemblerTextComponent } from "./assembler-text/assembler-text.component";
import { AssemblerCellComponent } from "./assembler-cell/assembler-cell.component";
import { AssemblerEmailComponent } from "./assembler-email/assembler-email.component";
import { AssemblerInformationTextComponent } from "./assembler-information-text/assembler-information-text.component";
import { AssemblerDateComponent } from "./assembler-date/assembler-date.component";
import { AssemblerMultipleTypeComponent } from "./assembler-multiple-type/assembler-multiple-type.component";
import { AssemblerInformationVideoComponent } from "./assembler-information-video/assembler-information-video.component";
import { AssemblerInformationVideoPersonalizadoComponent } from "./assembler-information-video-personalizado/assembler-information-video-personalizado.component";
import { AssemblerUnicTypeDropdownComponent } from "./assembler-unicy-type-dropdown/assembler-unic-type-dropdown.component";
import { AssemblerUnicTypeCompact } from "./assembler-unic-type-compact/assembler-unic-type-compact.component";

@NgModule({
    declarations: [
        AssemblerComponent,
        AssemblerUnicTypeComponent,
        AssemblerTextComponent,
        AssemblerCellComponent,
        AssemblerEmailComponent,
        AssemblerInformationTextComponent,
        AssemblerDateComponent,
        AssemblerMultipleTypeComponent,
        AssemblerInformationVideoComponent,
        AssemblerInformationVideoPersonalizadoComponent,
        AssemblerUnicTypeDropdownComponent,
        AssemblerUnicTypeCompact
    ],
    imports: [
        CommonModule,
        ComponentsModule
    ],
    exports: [
        AssemblerComponent
    ]
})
export class AssemblerModule{

}
