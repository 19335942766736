import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";

@Component({
    selector: 'layout-v2-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent{

    @Input()
    public titulo: string = "";

    @Input()
    public placeholder: string | undefined | null = "";

    @Input()
    public msgErro: string | undefined | null = "";

    @Input()
    public exibirErro: boolean = false;

    @Input()
    public alternativas: string[] = [];

    @Output()
    public alternativaSelecionada: EventEmitter<string> = new EventEmitter<string>();

    public opcao!: string;

    constructor(){}

    public onAlternativaSelecionada(event: MatSelectChange) {
        if(event.value) this.exibirErro = false;
        this.alternativaSelecionada.emit(event.value);
    }

}