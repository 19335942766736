import { AfterViewInit, Component, Input } from "@angular/core";
import { ComponentsService } from "../components.service";

@Component({
    selector: 'layout-v2-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements AfterViewInit{

    @Input()
    public idPergunta!: number;

    @Input()
    public heightBoxImage!: number | undefined;

    @Input()
    public marginTopBoxImagem!: number | undefined;

    @Input()
    public imagemBase64!: string | undefined;

    @Input()
    public showTextImage!: boolean;

    @Input()
    public textImage!: string | undefined;

    constructor(
        private componentsService: ComponentsService
    ){}

    ngAfterViewInit(): void {
        if(this.showTextImage && this.textImage){
            let t = document.querySelector(`#${this.getIdText()}`) as HTMLHeadElement;
            if(!t) return;
            let newText = this.componentsService.formatText(this.textImage);
            t.innerHTML = newText;
        }
    }

    public getImagem(): string{
        return `data:image/png;base64,${this.imagemBase64}`;
    }

    public getIdText():string{
        return `text-${this.idPergunta}`;
    }

}
