<div class="cabecalho espacao"
    *ngIf="estilo == null
    || (estilo?.opcaoCabecalho == 0
        && (estilo.exibeImg1Cabecalho == true || estilo.exibeImg2Cabecalho == true))">
    <div id="img1Cab"></div>
    <div id="img2Cab"></div>
</div>

<div class="cabecalho centralizado"
*ngIf="estilo?.opcaoCabecalho == 1 && estilo?.exibeImg1Cabecalho == true">
    <div id="img1Cab"></div>
</div>
