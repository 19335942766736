<layout-v2-splashscreen *ngIf="!showContent && getExibiSplashscreen()" (end)="stopAnimation()"></layout-v2-splashscreen>

<ng-container *ngIf="showContent || !getExibiSplashscreen()">

    <layout-v2-header [estilo]="formulario.estilo?.extras"></layout-v2-header>
    <div class="box-container" #boxContainer>
        <layout-v2-assembler [perguntas]="perguntas" [estilo]="formulario.estilo"
            (answered)="onResponder($event)"
            (back)="onVoltar()" (eventVideo)="onEventVideo($event)"></layout-v2-assembler>
    </div>
    <div class="watermark"></div>
    <layout-v2-footer [estilo]="formulario.estilo?.extras"></layout-v2-footer>

</ng-container>

