// example-module.ts
import { Component, OnInit, Input} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { YoutubePlayerService } from './youtube-player-service';
import { Observable } from 'rxjs';

let apiLoaded = false;

// example-component.ts
@Component({
    template: '<youtube-player [width]="width" [height]="height" [videoId]="videoId"></youtube-player>',
    selector: 'youtube-player-layout-v2',
})
export class YoutubePlayerLayoutV2Component implements OnInit {

    @Input() videoId: string | undefined;

    @Input()
    public width: number = 0;

    @Input()
    public height: number = 0;

    readonly stateChange: Observable<YT.OnStateChangeEvent> | undefined;

    constructor(
        private youtubePlayer: YouTubePlayer,
        private youtubePlayerService: YoutubePlayerService)
    {
        this.youtubePlayerService.youtubeComponent = this;
    }

    ngOnInit() {
        if (!apiLoaded) {
            // This code loads the IFrame Player API code asynchronously, according to the instructions at
            // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            apiLoaded = true;
        }
    }

    public getYoutubeService() {
        return this.youtubePlayerService;
    }

    public getTempoAtual() {
        return this.youtubePlayer.getCurrentTime();
    }

    public getTempoTotalVideo() {
        return this.youtubePlayer.getDuration();
    }

    public playVideo() {
        this.youtubePlayer.playVideo();
    }

    public pauseVideo() {
        this.youtubePlayer.pauseVideo();
    }

}
