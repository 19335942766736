import { NgModule } from "@angular/core";
import { ControllerService } from "./controller.service";
import { IdSessaoService } from "./id-sessao.service";
import { FormularioService } from "./formulario.service";
import { LocalStorageService } from "./local-storage.service";
import { UtmService } from "./utm.service";
import { IpService } from "./ip.service";
import { ExitIntentService } from "./exit-intent.service";
import { APICadFormService } from "./api-cadForm.service";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { MarcacaoService } from "./marcacao.service";
import { RegistradorRespostaService } from "./registrador-resposta.service";
import { ValidadorRespostaService } from "./validador-resposta.service";
import { FinalizadorFormularioService } from "./finalizador-formulario.service";
import { EnvioAPIService } from "./envio-api.service";
import { BurladorService } from "./burlador.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule
    ],
    providers: [
        ControllerService,
        IdSessaoService,
        FormularioService,
        LocalStorageService,
        UtmService,
        IpService,
        APICadFormService,
        ExitIntentService,
        MarcacaoService,
        ValidadorRespostaService,
        RegistradorRespostaService,
        FinalizadorFormularioService,
        EnvioAPIService,
        BurladorService
    ]
})
export class ServicesModule{

}
