export class PerguntaLink {

    redirecionamentoLink?: string
    id: string | undefined

    constructor(redir?: string, id?: string) {
        this.redirecionamentoLink = redir;
        this.id = id;
    }

    obterRedirecionamento() {  ;
        if (this.redirecionamentoLink && this.id) {
            return this.redirecionamentoLink.replace("_id_", this.id);
        } else if (this.redirecionamentoLink) {
            return this.redirecionamentoLink;
        }

        return "";
    }

}
