import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ContainerComponent } from "./container.component";
import { CampoFormularioModule } from "./campo-formulario/campo-formulario.module";
import { BarraProgressoModule } from "./barra-progresso/barra-progresso.module";

@NgModule({
    declarations: [
        ContainerComponent
    ],
    imports: [
        CommonModule,
        CampoFormularioModule,
        BarraProgressoModule
    ],
    exports: [
        ContainerComponent
    ]
})
export class ContainerModule{

}
