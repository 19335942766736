import { AfterViewInit, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'layout-v2-splashscreen',
    templateUrl: './splashscreen.component.html',
    styleUrls: ['./splashscreen.component.scss']
})
export class SplashscreenComponent implements AfterViewInit{

    @Output()
    public end: EventEmitter<void> = new EventEmitter<void>();

    public animacao = "/assets/animacoes/animacoes-teste/animation_1.json";

    constructor(){
        /*let widthDevice = window.screen.width;
        if(widthDevice <= 540){ //Small
            this.animacao = '/assets/animacoes/mobile/animations/animation.json'
        }else{
            this.animacao = '/assets/animacoes/wide/animations/animation.json'
        }*/
    }

    ngAfterViewInit(): void {
        const player = document.querySelector("lottie-player") as any;
        player.addEventListener('complete', (event: any) => {
            this.end.emit();
        });
    }

}
