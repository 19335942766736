<ng-container *ngIf="type == 'text'">
    <mat-form-field class="custom-input">
        <mat-label>{{label}}</mat-label>
        <input type="text" matInput [placeholder]="placeholder" [formControl]="campoInput">
    </mat-form-field>
</ng-container>

<ng-container *ngIf="type == 'email'">
    <mat-form-field class="custom-input">
        <mat-label>Email</mat-label>
        <input type="email" matInput placeholder="digite seu melhor email" [formControl]="campoInput">
        <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="type == 'whatsApp'">
    <mat-form-field class="custom-input">
        <mat-label>WhatsApp</mat-label>
        <input type="tel" matInput placeholder="(00) 00000-0000" mask="(00) 00000-0000" [formControl]="campoInput">
        <mat-icon matSuffix>call</mat-icon>
    </mat-form-field>
</ng-container>


<ng-container *ngIf="type == 'date'">
    <mat-form-field class="custom-input">
        <mat-label>{{label}}</mat-label>
        <input type="date" matInput [placeholder]="placeholder" [formControl]="campoInput">
    </mat-form-field>
</ng-container>

<ng-container *ngIf="type == 'hour'">
    <mat-form-field class="custom-input">
        <mat-label>{{label}}</mat-label>
        <input type="time" matInput [placeholder]="placeholder" [formControl]="campoInput">
    </mat-form-field>
</ng-container>

<ng-container *ngIf="type == 'date-hour'">
    <mat-form-field class="custom-input">
        <mat-label>{{label}}</mat-label>
        <input type="datetime-local" matInput [placeholder]="placeholder" [formControl]="campoInput">
    </mat-form-field>
</ng-container>

