<div class="box-video" [style]="getEstiloVideo()" [id]="getIdVideoBox(id)">

    <youtube-player-layout-v2 [id]="getIdVideo(id)" suggestedQuality="hd720"
        [width]="getLarguraVideo(id)" [height]="getAlturaVideo(id)"
        [videoId]="getLinkVideo()" style="width: 99.9% !important;"></youtube-player-layout-v2>
        

    <img [src]="gifVideo" (click)="playVideoGif(id)"
        style="width: 99.9% !important;"
        *ngIf="showGifOverlay()" alt="Video">

</div>
