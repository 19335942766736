import { Injectable } from "@angular/core";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { TipoValidacao, Validacao } from "src/app/models/validacao.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { ViewMensagemService } from "src/app/view/services/view-mensagem.service";

@Injectable()
export class ValidadorRespostaService{

    constructor(
        private viewMensagemService: ViewMensagemService
    ){}

    public validar(resposta: RespostaUsuario): boolean{

        let pergunta = resposta.pergunta as Pergunta;

        let valido: boolean = true;

        if (pergunta?.resposta.tipoResposta == TipoResposta.INFORMATIVO_TEXTO || pergunta?.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO
            || pergunta.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN 
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
            || pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA || pergunta?.resposta.tipoResposta == TipoResposta.MARCACAO_USUARIO) {
            return valido;
        }

        pergunta?.resposta.validacoes?.forEach(validacao => {
            if(!this.validarResposta(pergunta?.resposta.tipoResposta as TipoResposta, validacao, resposta.resposta as string)){
                this.viewMensagemService.solicitarAlertaMensagem(validacao.mensagem);
                valido = false;
            }
            if(!valido) return;
        });

        if (!valido) return valido;

        if(pergunta.resposta.tipoResposta == TipoResposta.CELULAR){
            valido = this.validarCelular(pergunta.resposta.tipoResposta, resposta.resposta);
            if(valido) this.viewMensagemService.encerrarAlertaMensagem();
            return valido;
        }

        if(pergunta.resposta.tipoResposta == TipoResposta.EMAIL && (pergunta.resposta?.validacoes as Validacao[])[0]){
            valido = this.validarEmail(pergunta.resposta.tipoResposta, (pergunta.resposta?.validacoes as Validacao[])[0], resposta.resposta);
            if(valido) this.viewMensagemService.encerrarAlertaMensagem();
            return valido;
        }



        if(valido) this.viewMensagemService.encerrarAlertaMensagem();

        return valido;

    }

    private validarResposta(tipoResposta: TipoResposta, validacao: Validacao, valor: string | undefined): boolean {

        if (validacao.tipo == TipoValidacao.REQUERIDA && validacao.valor == "true" && (valor == undefined || valor == "")) return false;

        let valorRespondido: any;
        let valorReferencia: any;

        switch (tipoResposta) {
            case TipoResposta.DATA:
                valorRespondido = this.montarData(valor as string);
                valorReferencia = this.montarData(this.inverterData(validacao.valor));
                break;
            case TipoResposta.HORA:
                valorRespondido = this.montarHora(valor as string);
                valorReferencia = this.montarHora(validacao.valor);
                break;
            case TipoResposta.DATA_HORA:
                valorRespondido = this.montarDataHora(valor as string);
                valorReferencia = this.montarData(this.inverterData(validacao.valor));
                break;
            case TipoResposta.NUMERO_INTEIRO:
                valorRespondido = parseInt(valor as string);
                valorReferencia = parseInt(validacao.valor);
                break;
            case TipoResposta.NUMERO_MOEDA:
            case TipoResposta.NUMERO_DECIMAL:
                valorRespondido = parseFloat(valor as string);
                valorReferencia = parseFloat(validacao.valor);
                break;
            case TipoResposta.TEXTO:
                valorRespondido = (valor as string).length;
                valorReferencia = parseInt(validacao.valor);
                break;
        }

        if ((validacao.tipo == TipoValidacao.VALOR_MINIMO && valorRespondido < valorReferencia)
            || (validacao.tipo == TipoValidacao.VALOR_MÁXIMO && valorRespondido > valorReferencia)) return false;

        if (TipoResposta.TEXTO && validacao.tipo == TipoValidacao.ALFANUMERICO) {
            let regex = /[0-9]/;
            if (regex.test(valor as string)) return false;
        }

        return true;

    }

    private inverterData(data: string): string {
        let partes = data.split("-");
        return `${partes[2]}/${partes[1]}/${partes[0]}`;
    }

    private montarData(data: string): Date{
        let partes = data.split("/");
        partes.forEach(parte => console.log(parte));
        return new Date(parseInt(partes[2]), parseInt(partes[1]), parseInt(partes[0]));
    }

    private montarHora(hora: string): Date {
        let partes = hora.split(":");
        return new Date(1970, 1, 1, parseInt(partes[0]), parseInt(partes[1]), parseInt(partes[2]));
    }

    private montarDataHora(dataHora: string): Date {
        let partes = dataHora.split(" ");
        let partesData = partes[0].split("/");
        let partesHora = partes[1].split(":");
        return new Date(parseInt(partesData[2]), parseInt(partesData[1]), parseInt(partesData[0]),
            parseInt(partesHora[0]), parseInt(partesHora[1]), parseInt(partesHora[0]));
    }

    private validarCelular(tipoResposta: string, resposta: any): boolean{
        if (tipoResposta != TipoResposta.CELULAR) return true;
        resposta = resposta as string;
        let regex1 = /\([0-9]{2}\)\s9[0-9]{4}\-[0-9]{4}/;
        let regex2 = /[0-9]{11}/;
        if (!regex1.test(resposta) && !regex2.test(resposta)) {
            this.viewMensagemService.solicitarAlertaMensagem("Ops, aparentemente esse não é um WhatsApp válido. Por favor, revise o número.");
            return false;
        }
        return true;
    }

    private validarEmail(tipoResposta: string, validacao: Validacao, resposta: any): boolean{
        if (tipoResposta != TipoResposta.EMAIL) return true;
        resposta = resposta as string;
        if (resposta !== '' || validacao.tipo == TipoValidacao.REQUERIDA && validacao.valor == "true") {

            const patternEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

            if (!patternEmail.test(resposta)) {
                this.viewMensagemService.solicitarAlertaMensagem("Ops, aparentemente esse não é um e-mail válido. Por favor, revise e tente novamente.");
                return false;
            }

        }
        return true;
    }

}
