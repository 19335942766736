import { Component, DoCheck, EventEmitter, Input,Output } from "@angular/core";
import { EstiloAlternativa } from "src/app/models/estilo-alternativa.model";

@Component({
    selector: 'layout-v2-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements DoCheck{

    @Input()
    public id!:number;

    @Input()
    public type: string = '0';

    @Input()
    public letter: string = "";

    @Input()
    public title: string = "";

    @Input()
    public isSelection: boolean = false;

    @Input()
    public styleButton!: EstiloAlternativa | undefined;

    @Input()
    public ownStyle: boolean = false;

    @Input()
    public showLetter: boolean = true;

    @Input()
    public trocarCor: boolean = false;

    @Input()
    public compacto: boolean = false;

    @Output()
    public clicked: EventEmitter<string> = new EventEmitter<string>();

    constructor(){}

    ngDoCheck(): void {
        if(this.styleButton && this.ownStyle){
            this.alterStyleButton();
        }
    }

    public onClick(){
        this.clicked.emit(this.title);
    }

    private alterStyleButton(){

        let btn = document.querySelector(`#${this.getIdButton()}`) as HTMLDivElement;

        if(!btn) return;

        if(this.isSelection) {
            this.styleBtnSelected(btn);
        }else{
            this.styleBtnNotSelected(btn);
        }

    }

    private styleBtnSelected(btn: HTMLDivElement){
        btn.style.backgroundColor = this.styleButton?.corFundoSelecionado as string;
        btn.style.color = this.styleButton?.corTituloSelecionada as string;
        btn.style.border =  `1px solid ${this.styleButton?.corBordaSelecionada as string}`;
        let letraBtn = btn.querySelector(".letter") as HTMLSpanElement;
        if(!letraBtn) return;
        letraBtn.style.backgroundColor = this.styleButton?.corFundoLetraSelecionado as string;
        letraBtn.style.color = this.styleButton?.corLetraSelecionada as string;
        letraBtn.style.border = `1px solid ${this.styleButton?.corBordaLetraSelecionada as string}`;
    }

    private styleBtnNotSelected(btn: HTMLDivElement){
        btn.style.backgroundColor = this.styleButton?.corFundo as string;
        btn.style.color = this.styleButton?.corTitulo as string;
        btn.style.border = `1px solid ${this.styleButton?.corBorda as string}`;
        let letraBtn = btn.querySelector(".letter") as HTMLSpanElement;
        if(!letraBtn) return;
        letraBtn.style.backgroundColor = this.styleButton?.corFundoLetra as string;
        letraBtn.style.color = this.styleButton?.corLetra as string;
        letraBtn.style.border = `1px solid ${this.styleButton?.corBordaLetra as string}`;
    }

    public getIdButton(): string{
        return `id-btn-${this.type}-${this.id}`;
    }

}
