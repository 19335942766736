import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ObservablesService } from 'src/app/view/services/obervables.service';

interface CustomWindow extends Window {
    [key: string]: any;
}

@Component({
    selector: 'layout-v1-campo-html',
    template: '<div style="overflow: auto; position:relative" [innerHTML]="htmlContent"></div>',
})
export class CampoHtmlComponent implements OnInit {

    @Input()
    public html!: string;

    public htmlContent: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private observableService: ObservablesService
    ) {
        this.htmlContent = '';
    }

    ngOnInit(): void {
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.html);
        this.observableService.data$.subscribe(() => {
            //usado para executar o script quando passa a pergunta
            this.executarTodosScripts();
        });
    }

    ngAfterViewInit() {
        this.carregarScripts();
    }

    executarTodosScripts() {
        const functionRegex = /\bfunction\s+([^\s(]+)/g; // encontra todas functions no html
        const sourceCode = document.documentElement.outerHTML;
        // itera sobre todas as correspondências da expressão regular
        let match;
        while ((match = functionRegex.exec(sourceCode)) !== null) {
            this.chamarFuncaoJavaScript(match[1]);
        }
    }
    // encontra e chama uma função por nome
    chamarFuncaoJavaScript(name: string) {
        const fn = (window as CustomWindow)[name];
        if (typeof fn === 'function') {
            fn();
        } else {
            console.error(`Function ${name} not found`);
        }
    }

    carregarScripts() {
        const elementoSucess = document.querySelector('#scripts-sucess'); //só pra saber se já adicionou no html

        if (!elementoSucess) {
            const scripts = document.querySelectorAll('script');
            scripts.forEach((script) => {
                if (script.hasAttribute('src') && script.hasAttribute('id')) {
                    if (!document.querySelector(script.id)) {
                        var scriptNewElement = document.createElement('script');
                        scriptNewElement.type = 'text/javascript';
                        scriptNewElement.src = script.src;
                        document.body.appendChild(scriptNewElement);
                    }
                } else if (!script.hasAttribute('src')) {
                    const newScript = this.renderer.createElement('script');
                    this.renderer.setProperty(
                        newScript,
                        'innerHTML',
                        script.innerHTML
                    );
                    this.renderer.appendChild(document.body, newScript);
                }
            });
            const elementoNewSucess = this.renderer.createElement('div');
            this.renderer.setProperty(
                elementoNewSucess,
                'id',
                'scripts-sucess'
            );
            this.renderer.appendChild(document.body, elementoNewSucess);
        }
    }
}
