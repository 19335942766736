import { Injectable } from '@angular/core';
import { YoutubePlayerLayoutv1Component } from './youtube-player.component';

@Injectable()
export class YoutubePlayerService {

    public youtubeComponent: YoutubePlayerLayoutv1Component | undefined;

    public play() {
        this.youtubeComponent?.playVideo();
    }

    public pause() {
        this.youtubeComponent?.pauseVideo();
    }

    public getTempoAtual() {
        return this.youtubeComponent?.getTempoAtual();
    }

    public getTempoTotal() {
        return this.youtubeComponent?.getTempoTotalVideo();
    }

}
