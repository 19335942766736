import { Injectable } from "@angular/core";
import { ExitIntentService } from "./exit-intent.service";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { Observable, switchMap } from "rxjs";
import { EnvioAPIService } from "./envio-api.service";
import { DadosEnvioAPI } from "../models/dados-envio-api.model";
import { LocalStorageService } from "./local-storage.service";
import { Burlador } from "../models/burlador.model";

@Injectable()
export class FinalizadorFormularioService{

    constructor(
        private exitIntentService: ExitIntentService,
        private envioAPIService: EnvioAPIService,
        private localService: LocalStorageService,
    ){}

    public finalizar(respostas: Map<string, string | string[] | null>,
        resposta: RespostaUsuario, pergunta: Pergunta, burladores: Burlador[]): Observable<string>{

        if (pergunta?.ultima &&
            pergunta?.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA && 
            pergunta?.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_DROPDOWN &&
            pergunta?.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_COMPACTA) {

            if (pergunta.opcaoRedirecionamento == 0) {
                return this.enviarAPI(respostas, pergunta.envioAPI as DadosEnvioAPI);

            } else if (pergunta.opcaoRedirecionamento == 1) {
                this.exitIntentService.removerListener();
                let tipoUsuario = this.localService.obterItem("tipoUsuario");
                if(tipoUsuario && tipoUsuario == 'Burlador_Desistente') {
                    let nomeBurlador = this.localService.obterItem("nomeBurlador") as string;
                    let burlador = burladores.filter(b => b.nome == nomeBurlador)[0];
                    if(burlador.linkProprio == true && burlador.link != null) return new Observable<string>(observable => observable.next(burlador.link));
                }
                pergunta.redirecionamentoLink = this.incluirIndicacao(pergunta.redirecionamentoLink as string);
                return new Observable<string>(observable => observable.next(pergunta.redirecionamentoLink as string));
            }

        } else if ((pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN)
            && pergunta.ultima) {

            if (pergunta.opcaoRedirecionamento == 0) {
                this.enviarAPI(respostas, pergunta.envioAPI as DadosEnvioAPI, false).subscribe({next: link => { return link }});
                this.exitIntentService.removerListener();
                return new Observable<string>(observable => observable.next((resposta.resposta as Alternativa).redirecionamentoLink as string));

            } else if (pergunta.opcaoRedirecionamento == 1) {
                this.exitIntentService.removerListener();
                let tipoUsuario = this.localService.obterItem("tipoUsuario");
                if(tipoUsuario && tipoUsuario == 'Burlador_Desistente') {
                    let nomeBurlador = this.localService.obterItem("nomeBurlador") as string;
                    let burlador = burladores.filter(b => b.nome == nomeBurlador)[0];
                    if(burlador.linkProprio == true) return new Observable<string>(observable => observable.next(burlador.link));
                }
                pergunta.redirecionamentoLink = this.incluirIndicacao(pergunta.redirecionamentoLink as string);
                return new Observable<string>(observable => observable.next(pergunta.redirecionamentoLink as string));
            }

        } else if (pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA 
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
            || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN) {

            let resp = resposta.resposta as Alternativa;

            if (resp.ultima) {
                this.exitIntentService.removerListener();
                resp.redirecionamentoLink = this.incluirIndicacao(resp.redirecionamentoLink as string);
                return new Observable<string>(observable => observable.next(resp.redirecionamentoLink as string));
            }

        }

        return new Observable<string>(observable => observable.next(""));

    }

    private incluirIndicacao(linkWhatsApp: string): string{
        if(linkWhatsApp == null || linkWhatsApp == undefined || !linkWhatsApp.startsWith('https://api.whatsapp.com/send?phone=')) return linkWhatsApp;
        let indicacao = this.localService.obterItem('indicacao');
        if(indicacao == null || indicacao == undefined || indicacao == "undefined" ) return linkWhatsApp;
        return `${linkWhatsApp}%20(Código%20de%20indicação:%20${indicacao})`;
    }

    private enviarAPI(respostas: Map<string, string | string[] | null>,
        envioAPI: DadosEnvioAPI, redirecionarLink: boolean = true): Observable<string> {

        if (envioAPI.linkProprio) {
            return new Observable(observer => observer.next(envioAPI.link as string));
        }

        let dados = "";

        if (envioAPI.dados) {

            dados = "{";

            envioAPI.dados.forEach((dado, index) => {
                dados += `"${dado.campo}":`;
                if (dado.valorEspecifico) {
                    dados += `"${dado.valor}"`;
                } else {
                    dados += `"${respostas.get(dado.valor)}"`;
                }
                if ((envioAPI.dados.length as number) - 1 != index) {
                    dados += ",";
                }
            });

            dados += "}";

            dados = JSON.parse(dados);

        }

        return this.envioAPIService.enviarRespostasAPI(envioAPI.metodoHTTP, envioAPI.url, dados).pipe(
            switchMap( link => {
                if (redirecionarLink) return new Observable<string>(observer => observer.next(link.link as string));
                return new Observable<string>(observer => observer.next(""));
            })
        );

    }

}
