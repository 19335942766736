import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Formulario } from "../models/formulario.model";
import { RespostaUsuario } from "../models/resposta-usuario.model";
import { SpinnerService } from "./components/spinner/spinner.service";
import { ArvorePerguntaService } from "./services/arvore-pergunta.service";
import { Pergunta } from "../models/pergunta.model";
import { TipoResposta } from "../models/tipo-resposta.model";
import { Alternativa } from "../models/alternativa.model";
import { ObservablesService } from "./services/obervables.service";
import { ViewMensagemService } from "./services/view-mensagem.service";
import Swal from "sweetalert2";
import { PassadorService } from "./services/passador.service";

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnChanges{

    @Input()
    public formulario!: Formulario;

    @Output()
    public eventoVoltar:EventEmitter<void> = new EventEmitter();

    @Output()
    public eventoResponder:EventEmitter<RespostaUsuario> = new EventEmitter();

    @Output()
    public eventoVideo:EventEmitter<any> = new EventEmitter<any>();

    public liberarTela: boolean = false;

    public versaoLayout: string = "v1";

    private todasPerguntas!: Pergunta[];

    public perguntas: Pergunta[] = [];

    private perguntasForm!: Pergunta[];

    constructor(
        private spinnerService: SpinnerService,
        private arvorePerguntaService: ArvorePerguntaService,
        private observableService: ObservablesService,
        private viewMensagemService: ViewMensagemService,
        private passadorService: PassadorService
    ){
        this.spinnerService.ativarSpinner();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.formulario) {
            this.perguntasForm = this.formulario.perguntas;
            if(this.formulario.versaoPrevNator) this.versaoLayout = this.formulario.versaoPrevNator;
            this.todasPerguntas = this.formulario.perguntas;
            this.perguntas.push(this.todasPerguntas[0]);
            this.arvorePerguntaService.instanciarArvorePergunta(this.todasPerguntas);
            this.perguntas = this.arvorePerguntaService.buscarCaminho(this.todasPerguntas[0]) as Pergunta[];
            this.liberarTela = true;
            this.spinnerService.desativarSpinner();
        }
        this.exibirMensagem();
    }

    private exibirMensagem(){
        this.viewMensagemService.alertarMensagem().subscribe({
            next: msg => {
                if(msg) {
                    Swal.fire({
                        icon: 'warning',
                        text: `${msg}`,
                        title: `ATENÇÃO`,
                    });
                    this.passadorService.setPassador(false);
                }else{
                    this.passadorService.setPassador(true);
                }
            }
        });
    }

    public onResponder(resposta: RespostaUsuario){

        let pergunta = resposta.pergunta as Pergunta;

        let passador$ = this.passadorService.getPassador().subscribe({
            next: isPassarPergunta => {

                if(!pergunta.ultima && (pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA
                    || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN 
                    || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
                    || pergunta.resposta.tipoResposta == TipoResposta.MARCACAO_USUARIO)) {

                    let resp = resposta.resposta as Alternativa;

                    if(isPassarPergunta && resp.ultima && resp.redirecionamentoLink) this.spinnerService.ativarSpinner();

                } else if(pergunta.ultima && isPassarPergunta) {
                    this.spinnerService.ativarSpinner();
                }

            }
        });

        passador$.unsubscribe();

        this.refazArvorePergunta(resposta);
        this.espacoPerguntaHTML(resposta);
        this.eventoResponder.emit(resposta);

    }

    private refazArvorePergunta(resposta: RespostaUsuario){
        if (resposta.pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA
                || resposta.pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN
                || resposta.pergunta?.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
                || resposta.pergunta?.resposta?.tipoResposta == TipoResposta.MARCACAO_USUARIO) {
            let resp = resposta.resposta as Alternativa;
            let redirecionamento = resp.redirecionamentoPergunta;
            if (redirecionamento && !resp?.ultima) {

                let per = this.todasPerguntas.find(p => p.id == redirecionamento) as Pergunta;

                //tira as possiveis perguntas
                let index = this.perguntas.indexOf(resposta.pergunta);
                if (index > -1) this.perguntas = this.perguntas.slice(0, index + 1);

                //Adiciona caminho novo
                let caminhoNovo = this.arvorePerguntaService.buscarCaminho(per) as Pergunta[];

                caminhoNovo?.forEach(c => this.perguntas.push(c));

            }
        }
    }

    private espacoPerguntaHTML(resposta: RespostaUsuario) {
        let index = this.perguntas.indexOf(resposta.pergunta);
        if (index == -1) return;
        index++;
        if (index < this.perguntas.length && this.perguntas[index].resposta.tipoResposta == TipoResposta.HTML) {
            setTimeout(() => { this.observableService.updateData(); }, 200);
        }
    }

    public onVoltar(){
        this.passadorService.setPassador(true);
        this.eventoVoltar.emit();
    }

    public onEventVideo(dado: object){
        this.eventoVideo.emit(dado);
    }

}
