import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Burlador } from "../models/burlador.model";
import { Observable } from "rxjs";

@Injectable()
export class BurladorService{

    private url: string = `${environment.URL_API_ADM_FORM}/api/v1/burlador/prevNator`;

    constructor(
        private http: HttpClient
    ){}

    public buscarBurladores(idFormulario: number): Observable<Burlador[]>{
        let urlLocal = `${this.url}/${idFormulario}`;
        return this.http.post<Burlador[]>(urlLocal,null);
    }

}
