import { AfterViewInit, Component, DoCheck, Input } from "@angular/core";
import { ComponentsService } from "../components.service";

@Component({
    selector: 'layout-v2-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss']
})
export class TitleComponent implements DoCheck, AfterViewInit{

    @Input()
    public title!: string | null;

    @Input()
    public idPergunta!: number;

    constructor(
        private componentsService: ComponentsService
    ){}

    ngDoCheck(): void {
        this.definirTitulo();
    }

    ngAfterViewInit(): void {
        this.definirTitulo();
    }

    private definirTitulo(){
        if(this.title){
            let t = document.querySelector(`#${this.getIdTitle()}`) as HTMLHeadElement;
            if(!t) return;
            let newText = this.componentsService.replaceVariable(this.title);
            newText = this.componentsService.formatText(newText);
            t.innerHTML = newText;
        }
    }

    public getIdTitle():string{
        return `t-${this.idPergunta}`;
    }

}
