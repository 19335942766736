import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ButtonComponent } from "./button/button.component";
import { DescriptionComponent } from "./description/description.component";
import { TitleComponent } from "./title/title.component";
import { WizzardComponent } from "./wizzard/wizzard.component";
import { CommonModule } from "@angular/common";
import { InputComponent } from "./input/input.component";
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { VideoComponent } from "./video/video.component";
import { VideoService } from "./video/video.service";
import { YoutubePlayerLayoutV2Component } from "./youtube-player/youtube-player.component";
import { YoutubePlayerService } from "./youtube-player/youtube-player-service";
import { YouTubePlayer, YouTubePlayerModule } from "@angular/youtube-player";
import { ComponentsService } from "./components.service";
import { ImageComponent } from "./image/Image.component";
import { SplashscreenComponent } from "./splashscreen/splashscreen.component";
import { VideoPersonalizadoComponent } from "./video-personalizado/video-personalizado.component";
import { DropdownComponent } from "./dropdown/dropdowm.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ButtonComponent,
        DescriptionComponent,
        TitleComponent,
        WizzardComponent,
        InputComponent,
        VideoComponent,
        YoutubePlayerLayoutV2Component,
        ImageComponent,
        SplashscreenComponent,
        VideoPersonalizadoComponent,
        DropdownComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forChild(),
        YouTubePlayerModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        ButtonComponent,
        DescriptionComponent,
        TitleComponent,
        WizzardComponent,
        InputComponent,
        VideoComponent,
        YoutubePlayerLayoutV2Component,
        ImageComponent,
        SplashscreenComponent,
        VideoPersonalizadoComponent,
        DropdownComponent
    ],
    providers: [
        ComponentsService,
        VideoService,
        YoutubePlayerService,
        YouTubePlayer
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule{

}
