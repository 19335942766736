<div class="box-video" [style]="getEstiloVideo()" [id]="getIdVideoBox(id)">

    <!--
    <button [id]="getIdBtnMinimizar(id)" class="btn-minimizar">X</button>-->

    <video [id]="getIdVideo(id)" (timeupdate)="contadorTempo($event)" 
        (click)="playPause(bPP)" (ended)="chegouFinal()">
        <source [src]="getSource(link)" />
    </video>

    <div class="controle">
        <button class="play" #bPP (click)="playPause(bPP)"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg></button>
        <div class="barra-andamento"><div class="barra" [id]="getIdBarra(id)"></div></div>
        <div class="tempo">{{tempoAtual}}  / {{tempoTotal}}</div>
        <button class="maximizar" #bM (click)="maximizar(bM)"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"/></svg></button>
    </div>

</div>