import { Injectable } from "@angular/core";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { RespostaEnvio } from "../models/resposta-envio.model";
import { APICadFormService } from "./api-cadForm.service";

@Injectable()
export class RegistradorRespostaService{

    private respostas: Map<string, string | string[] | null> = new Map();

    constructor(
        private apiCadFormService: APICadFormService
    ){}

    public registrar(idUsuario:number, resposta: RespostaUsuario) {

        let pergunta = resposta.pergunta as Pergunta;

        if (pergunta?.resposta.guarda) this.guardarResposta(resposta);

        let resp:string | null = null;

        let campo = pergunta?.identificador;

        if(pergunta?.resposta.tipoResposta == TipoResposta.INFORMATIVO_TEXTO ||
            pergunta?.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO ||
            pergunta.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO ||
            pergunta?.resposta.tipoResposta == TipoResposta.HTML ){

            resp = null;

        }else if(pergunta?.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA
            && pergunta?.resposta.tipoResposta != TipoResposta.MARCACAO_USUARIO 
            && pergunta.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_DROPDOWN
            && pergunta.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_COMPACTA){

            resp = resposta.resposta as string;

            if (pergunta?.resposta.tipoResposta == TipoResposta.CELULAR) {
                resp = resp.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
                resp = "55" + resp;
            }

        }else{
            resp = (resposta.resposta as Alternativa).titulo;
        }

        this.respostas.set(campo, resp);
        this.registrarRespostaPergunta(idUsuario, pergunta, resp);

    }

    private registrarRespostaPergunta(idUsuario:number, pergunta: Pergunta, resposta: string | null) {

        let respostaPergunta = {
            idPergunta: pergunta.id,
            resposta: resposta
        } as RespostaEnvio;

        if(pergunta.resposta.tipoResposta == TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO) {
            respostaPergunta.assistiuVideo = sessionStorage.getItem('assistiuVideo') != null ? sessionStorage.getItem('assistiuVideo') as string : 'false';
            respostaPergunta.deuPlayVideo = sessionStorage.getItem('deuPlayVideo') != null ? sessionStorage.getItem('deuPlayVideo') as string : 'false';
            respostaPergunta.tempoAssistidoVideo = sessionStorage.getItem('tempoAssistidoVideo') != null ? sessionStorage.getItem('tempoAssistidoVideo') as string : '0';
            sessionStorage.clear();
        }

        this.apiCadFormService.registrarRespostaPergunta(idUsuario,respostaPergunta).subscribe({
            next: retorno => { }, error: error => { console.log(error) }
        });

    }

    public getRespostas(): Map<string, string | string[] | null>{
        return this.respostas;
    }

    private guardarResposta(resposta: RespostaUsuario){
        if(resposta.pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA ||
            resposta.pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA ||
            resposta.pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN ||
            resposta.pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA ||
            resposta.pergunta.resposta.tipoResposta == TipoResposta.MARCACAO_USUARIO){
                sessionStorage.setItem(resposta.pergunta.identificador,(resposta.resposta as Alternativa).titulo);
        }else{
            sessionStorage.setItem(resposta.pergunta.identificador, resposta.resposta as string);
        }
    }


}
