import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { Validacao } from "src/app/models/validacao.model";

@Component({
    selector: 'layout-v2-assembler-unic-type-dropdown',
    templateUrl: './assembler-unic-type-dropdown.component.html',
    styleUrls: ['./assembler-unic-type-dropdown.component.scss']
})
export class AssemblerUnicTypeDropdownComponent{
   
    @Input()
    public pergunta!: Pergunta;

    @Input()
    public enableBackButton: boolean = true;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    public exibirMsgErro: boolean = false;
    public mudarCorBtn: boolean = false;

    private alternativaSelecionada!: Alternativa;

    public onAnswered(){
        if((this.pergunta.resposta.validacoes as Validacao[])[0] &&
            (this.pergunta.resposta.validacoes as Validacao[])[0].valor == "true" &&
            (this.alternativaSelecionada == null || this.alternativaSelecionada == undefined)) {
            this.exibirMsgErro = true;
            return;
        }else if(this.alternativaSelecionada == null || this.alternativaSelecionada == undefined){
            this.alternativaSelecionada = this.pergunta.resposta.alternativas?.find(a => a.opcaoDefault) as Alternativa;
        }
        let resposta = {
            pergunta: this.pergunta,
            resposta: this.alternativaSelecionada
        } as RespostaUsuario;
        this.answered.emit(resposta);
    }

    public onBack(){
        this.back.emit();
    }

    public converteAlternativa(): string[] {
        return this.pergunta.resposta.alternativas?.filter(a => !a.opcaoDefault).map(a => a.titulo) as string[];
    }

    public onOpcaoSelecionada(opcao: string) {
        this.mudarCorBtn = true;
        this.alternativaSelecionada = this.pergunta.resposta.alternativas?.find(a => a.titulo == opcao) as Alternativa;
    }

    public getMsgErro(): string{
        return (this.pergunta.resposta.validacoes as Validacao[])[0].mensagem as string;
    }

}