import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "./spinner.service";

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit{

    public ativo:boolean = false;

    constructor(
        private service:SpinnerService
    ){}

    ngOnInit(): void {
        this.service.getEventoSpinner().subscribe({ next: ativa => { this.ativo = ativa; } });
    }

}
