import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    adicionarItemComExpiracao(chave: string, valor: any): void {
        const item = {
            valor: valor,
            expiracao: new Date().getTime() + 60 * 60 * 1000 // Adiciona uma hora em milissegundos
            // expiracao: new Date().getTime() + 60 * 1000 // Adiciona um minuto em milissegundos
        };
        localStorage.setItem(chave, JSON.stringify(item));
    }

    obterItemComExpiracao(chave: string): any {
        const item = localStorage.getItem(chave);
        if (item) {
            const parsedItem = JSON.parse(item);
            if (new Date().getTime() >= parsedItem.expiracao) {
                localStorage.removeItem(chave);
                return null;
            }
            return parsedItem.valor;
        }
        return null;
    }

    adicionarItem(chave: string, valor: any): void{
        localStorage.setItem(chave,valor);
    }

    obterItem(chave: string): any{
        return localStorage.getItem(chave);
    }

}
