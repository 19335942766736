<section class="layout-v2-section">

    <ng-container *ngFor="let p of perguntas; let i = index">

        <div>

            <layout-v2-wizzard [steps]="p.totalIndiceBarraProgresso"
                *ngIf="estilo?.mostrarBarraProgresso"
                [currentStep]="p.indiceBarraProgresso"></layout-v2-wizzard>

            <layout-v2-assembler-text [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.TEXTO"
                    (answered)="onAnswered($event)" (back)="onBack()" ></layout-v2-assembler-text>

            <layout-v2-assembler-cell [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.CELULAR"
                    (answered)="onAnswered($event)" (back)="onBack()" ></layout-v2-assembler-cell>

            <layout-v2-assembler-email [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.EMAIL"
                    (answered)="onAnswered($event)" (back)="onBack()" ></layout-v2-assembler-email>

            <layout-v2-assembler-date [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.DATA"
                    (answered)="onAnswered($event)" (back)="onBack()" ></layout-v2-assembler-date>

            <layout-v2-assembler-information-text [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.INFORMATIVO_TEXTO"
                    (answered)="onAnswered($event)" (back)="onBack()" ></layout-v2-assembler-information-text>

            <layout-v2-assembler-unic-type [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA ||
                p.resposta.tipoResposta == tipoResposta.MARCACAO_USUARIO"
                        (answered)="onAnswered($event)" (back)="onBack()"></layout-v2-assembler-unic-type>

            <layout-v2-assembler-multiple-type [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.ALTERNATIVA_MULTIPLA"
                        (answered)="onAnswered($event)" (back)="onBack()"></layout-v2-assembler-multiple-type>

            <layout-v2-assembler-information-video [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.INFORMATIVO_VIDEO"
                        (answered)="onAnswered($event)" (back)="onBack()"></layout-v2-assembler-information-video>

            <layout-v2-assembler-information-video-personalizado [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO"
                    (answered)="onAnswered($event)" (back)="onBack()" (eventVideo)="onEventVideo($event)"></layout-v2-assembler-information-video-personalizado>

            <layout-v2-assembler-unic-type-dropdown [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA_DROPDOWN"
                    (answered)="onAnswered($event)" (back)="onBack()"></layout-v2-assembler-unic-type-dropdown>

            <layout-v2-assembler-unic-type-compact [pergunta]="p" [enableBackButton]="i > 0"
                *ngIf="p.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA_COMPACTA"
                    (answered)="onAnswered($event)" (back)="onBack()"></layout-v2-assembler-unic-type-compact>
        

        </div>

    </ng-container>

</section>
