import { Injectable } from "@angular/core";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { Pergunta } from "src/app/models/pergunta.model";

@Injectable()
export class ArvorePerguntaService {

    private noRaiz: No | undefined = undefined;

    constructor() { }

    private montarArvorePergunta(no: No, perguntas: Pergunta[]) {
        
        let pergunta = no?.valor;
       
        if ((pergunta?.ultima!=undefined 
            && pergunta?.ultima) || ((pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA 
                && pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_DROPDOWN 
                && pergunta.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_COMPACTA
                && pergunta?.resposta?.tipoResposta != TipoResposta.MARCACAO_USUARIO) &&
            (pergunta?.ultima==undefined || !pergunta?.ultima ) && !pergunta?.redirecionamentoPergunta)) {
            return;
        }
        if (pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA 
            && pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_DROPDOWN 
            && pergunta.resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_COMPACTA
            && pergunta?.resposta?.tipoResposta != TipoResposta.MARCACAO_USUARIO) {
            let proximaPergunta = perguntas.filter(p => p.id == pergunta?.redirecionamentoPergunta)[0];
            no = no?.addNo(proximaPergunta);
            this.montarArvorePergunta(no, perguntas);
        } else {
            pergunta?.resposta?.alternativas?.forEach(alternativa => {
                if (alternativa.redirecionamentoPergunta && (alternativa.ultima == undefined || !alternativa.ultima)) {
                    let p = perguntas.filter(p => p.id == alternativa.redirecionamentoPergunta)[0];
                    this.montarArvorePergunta(no?.addNo(p), perguntas);
                }
            });
        }
    }

    public instanciarArvorePergunta(perguntas: Pergunta[]) {
        this.noRaiz = new No(perguntas[0]);
        this.montarArvorePergunta(this.noRaiz, perguntas);
    }

    private montarCaminho(no: No, perguntas: Pergunta[]): Pergunta[] {
        let pergunta = no.valor;
        perguntas.push(no.valor);
        if ((pergunta.ultima != undefined && pergunta.ultima != null && pergunta.ultima) || no.noVazio() ||
        pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA 
        || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_COMPACTA
        || pergunta.resposta.tipoResposta == TipoResposta.ALTERNATIVA_UNICA_DROPDOWN 
        || pergunta.resposta.tipoResposta == TipoResposta.MARCACAO_USUARIO) return perguntas;
        no.nos.forEach(no => {
            this.montarCaminho(no, perguntas);
        });
        return perguntas;
    }

    public buscarCaminho(pergunta: Pergunta): Pergunta[] | undefined {
        let no = this.noRaiz?.buscarNo(pergunta);
        if (no != undefined) {
            let perguntas: Pergunta[] = [];
            return this.montarCaminho(no, perguntas);
        }
        return undefined;
    }

    public imprimir() {
        this.noRaiz?.imprimiArvore();
    }

}

class No {

    private _nos: No[] = [];

    constructor(private _valor: Pergunta) { }

    public get valor(): Pergunta {
        return this._valor;
    }

    public get nos(): No[] {
        return this._nos;
    }

    public addNo(pergunta: Pergunta): No {
        let no = new No(pergunta);
        this._nos.push(no);
        return no;
    }

    public buscarNo(pergunta: Pergunta, noRaiz?: No): No | undefined {
        if (noRaiz == undefined) noRaiz = this;
        if (noRaiz._valor.id == pergunta.id) {
            return noRaiz;
        } else {
            let no = undefined;
            for (let i = 0; i < noRaiz._nos.length; i++) {
                no = this.buscarNo(pergunta, noRaiz._nos[i]);
                if (no != undefined) break;
            }
            return no;
        }
    }

    public imprimiArvore(no?: No) {
        if (no == undefined) no = this;
        console.log(no._valor.titulo);
        console.log(no._nos.length);
        no._nos.forEach(no => {
            this.imprimiArvore(no);
        });
    }

    public noVazio(): boolean {
        return this._nos.length == 0;
    }

}
