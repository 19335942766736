import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { LocationStrategy } from '@angular/common';

@Injectable()
export class UtmService {

    private utmParams: { [key: string]: string } = {};
    private ipAddress: string;

    constructor(
        private locationStrategy: LocationStrategy,
        private location: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.ipAddress = '';
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.extractUtmParams();
            });
    }

    getIp() {
        return this.ipAddress;
    }

    private extractUtmParams() {
        this.utmParams = {};
        // const domain = this.locationStrategy.prepareExternalUrl('');
        const fullUrl = this.location.path();

        // const currentUrl = fullUrl + '?' + new URLSearchParams(this.activatedRoute.snapshot.queryParams).toString();

        /* this.activatedRoute.queryParams.subscribe(queryParams => {
             for (const key in queryParams) {
                 if (queryParams.hasOwnProperty(key)) { 
                     const value = queryParams[key];
                     this.utmParams[key] = value;
                     console.log(key + ': ' + value);
                 }
             }
         });
         */

        /* const utmKeys = [
             'utm_source',
             'utm_medium',
             'utm_campaign',
             'utm_term',
             'utm_content',
             'fbclid',
         ];
         for (const key of utmKeys) {
             if (queryParams.hasOwnProperty(key)) {
                 this.utmParams[key] = queryParams[key];
             }
         }
         */

        this.utmParams['userAgent'] = window.navigator.userAgent;
        this.utmParams['url'] = fullUrl;
    }

    getUtmParams(): { [key: string]: string } {
        return this.utmParams;
    }

}
