import { Injectable } from "@angular/core";
import { IdSessaoService } from "./id-sessao.service";
import { FormularioService } from "./formulario.service";
import { ActivatedRoute } from "@angular/router";
import { Observable, map, switchMap, tap } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { UtmService } from "./utm.service";
import { UsuarioFormularioRequisicao } from "../models/usuario-formulario-requisicao.model";
import { IpService } from "./ip.service";
import { APICadFormService } from "./api-cadForm.service";
import { Pergunta } from "src/app/models/pergunta.model";
import { Formulario } from "src/app/models/formulario.model";
import { PerguntaLink } from "../models/pergunta-link.model";
import { ExitIntentService } from "./exit-intent.service";
import { UsuarioFormularioRequisicaoIP } from "../models/usuario-formulario-requisicao-ip.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { MarcacaoService } from "./marcacao.service";
import { ValidadorRespostaService } from "./validador-resposta.service";
import { RegistradorRespostaService } from "./registrador-resposta.service";
import { FinalizadorFormularioService } from "./finalizador-formulario.service";
import { Burlador } from "../models/burlador.model";

@Injectable()
export class ControllerService {

    private idUsuario!: string;
    private nomeForm!: string;

    constructor(
        private idSessaoService: IdSessaoService,
        private formularioService: FormularioService,
        private localStorageService: LocalStorageService,
        private utmService: UtmService,
        private ipService: IpService,
        private apiCadFormService: APICadFormService,
        private exitIntentService: ExitIntentService,
        private marcacaoService: MarcacaoService,
        private validadorRespostaService: ValidadorRespostaService,
        private registradorRespostaService: RegistradorRespostaService,
        private finalizadorFormularioService: FinalizadorFormularioService
    ) { }

    public configuracoesIniciais() {
        this.definirSessaoID();
        this.configurarEventoVoltarNavegador();
    }

    private definirSessaoID() {
        let id = this.idSessaoService.recuperarIdSessao();
        if (id == null) this.idSessaoService.gerarIdSessao();
        sessionStorage.clear();
    }

    private configurarEventoVoltarNavegador() {
        history.pushState(null, '', location.href);
        window.onpopstate = function () {
            history.go(1);
        };
    }

    public buscarFormulario(activetedRoute: ActivatedRoute): Observable<Formulario> {

        activetedRoute.queryParams.subscribe({
            next: params => {
                this.localStorageService.adicionarItem('indicacao', params['indicacao']);
            }
        })

        var idUsuario = this.localStorageService.obterItem("idUsuario");
        return activetedRoute.params.pipe(
            map(params => {
                let urlForm = params['form'] as string;
                //   urlForm = this.verificarFormSalvo(urlForm); //ignorando o form salvo no navegador do usuário
                if (!urlForm) return;
                let idUsuario = this.localStorageService.obterItem("idUsuario");
                return { idUsuario, urlForm };
            }),
            switchMap(props => {
                let utmParams = this.utmService.getUtmParams();
                let requisicaoFormulario = {
                    nomeFormulario: props?.urlForm,
                    url: utmParams['url'],
                    idUsuario: idUsuario,
                    userAgent: utmParams['userAgent'],
                } as UsuarioFormularioRequisicao;
                return this.formularioService.buscarFormulario(requisicaoFormulario).pipe(
                    tap(formulario => {
                        if (!formulario.perguntas) return;
                        this.nomeForm = formulario.nome;
                        this.idUsuario = formulario.idUsuario.toString();
                        formulario.perguntas.forEach(pergunta => {
                            let link = this.marcacaoService.isMarcacaoPresente(this.nomeForm, pergunta);
                            if (link) this.onRedirecionar(this.idUsuario, link);
                        });
                        this.localStorageService.adicionarItemComExpiracao('form-salvo', formulario.url);
                        this.localStorageService.adicionarItem("idUsuario", this.idUsuario);
                        this.localStorageService.adicionarItem("tipoUsuario", formulario.tipoUsuario);
                        this.localStorageService.adicionarItem("nomeBurlador", formulario.nomeBurlador);
                        this.salvarIpUsuario(this.idUsuario).subscribe({ next: () => { } });
                    })
                )
            })
        );

    }

    private verificarFormSalvo(urlForm: string): string {
        let linkRedir = this.localStorageService.obterItemComExpiracao('form-salvo');
        if (linkRedir != undefined && linkRedir !== urlForm) {
            urlForm = linkRedir;
        }
        return urlForm;
    }

    private salvarIpUsuario(idUsuario: string): Observable<any> {
        return this.ipService.getIPAddress().pipe(
            map((res: any) => { return { idUsuario: parseInt(idUsuario), ip: res.ip } as UsuarioFormularioRequisicaoIP }),
            switchMap(params => { return this.apiCadFormService.salvarIpUsuario(params) })
        );
    }

    public tratarResposta(resposta: RespostaUsuario, burladores: Burlador[]) {

        let link = this.marcacaoService.isPerguntaMarcacao(this.nomeForm, resposta);
        if (link) this.onRedirecionar(this.idUsuario, link);

        if (!this.validadorRespostaService.validar(resposta)) return;

        this.registradorRespostaService.registrar(parseInt(this.idUsuario), resposta);

        this.finalizadorFormularioService.finalizar(
            this.registradorRespostaService.getRespostas(),
            resposta, resposta.pergunta as Pergunta, burladores)
            .subscribe(urlRedirecionamento => {
                if (urlRedirecionamento.length > 0) this.onRedirecionar(this.idUsuario, urlRedirecionamento);
            });

    }

    public tratarEventoVideo(dado: any){
        sessionStorage.setItem(dado[0],dado[1]);
    } 

    private onRedirecionar(idUsuario: string, link?: string) {
        this.exitIntentService.removerListener();
        let perguntaLink = new PerguntaLink(link, idUsuario);
        window.location.href = perguntaLink.obterRedirecionamento();
    }

}
