<div class="container-pergunta">
    <h2 class="texto-titulo" innerHTML="{{getPerguntaFormatada(pergunta?.titulo)}}" *ngIf="pergunta?.exibirTitulo"></h2>

    <div class="box-image" *ngIf="pergunta?.exibeImagem" >
        <img [src]="getImagem()" alt="imagem da pergunta"/>
    </div>

    <p *ngIf="pergunta?.exibeImagem && pergunta?.exibeTextoImagem" class="texto-imagem" innerHTML="{{getPerguntaFormatada(pergunta?.textoImagem)}}"></p>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta !== TipoResposta.HTML && pergunta?.descricao != ''">
        <h6 class="texto-descricao" innerHTML="{{getPerguntaFormatada(retirarLista(pergunta?.descricao))}}"></h6>
    </ng-container>

    <ng-container *ngIf="getPartesLista(pergunta?.descricao).length > 0">
        <ul class="personal-list">
            <li *ngFor="let item of getPartesLista(pergunta?.descricao)">
                <div class="bullet">
                    <svg style="margin-top: 3px;" width="30px" height="30px" viewBox="0 0 24.00 24.00" fill="none"
                        xmlns="http://www.w3.org/2000/svg" stroke="#00C29E" stroke-width="0.00024000000000000003">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                            stroke-width="0.048"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z"
                                fill="#00C29E"></path>
                        </g>
                    </svg>
                </div>
                <div class="texto">
                    {{item}}
                </div>
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_UNICA ||
        pergunta?.resposta?.tipoResposta == TipoResposta.MARCACAO_USUARIO">

        <ng-container *ngFor="let alternativa of pergunta?.resposta?.alternativas; let i = index">
            <div class="base-btn-alternativa btn-alternativa-padrao"
                [ngClass]="getNomeClasseGrupo(pergunta?.identificador)"
                [id]="getNomeCampoAlternativa(i,pergunta?.identificador)" #btn data-selecionado="null" (click)="responderUnico(pergunta,alternativa,getNomeCampoAlternativa(i,pergunta?.identificador),
                    getNomeClasseGrupo(pergunta?.identificador),alternativa)">
                <span class="base-letra-alternativa letra-alternativa-padrao ms-1 me-1">{{getLetraAlternativa(i) |
                    uppercase}}</span>
                {{alternativa.titulo}}
            </div>
            {{alterarEstiloBtnAlternativa(alternativa,btn)}}
        </ng-container>

    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.HTML">
        <layout-v1-campo-html [style]="getEstiloHtml()"
            [html]="getPerguntaFormatada(pergunta?.descricao)"></layout-v1-campo-html>
    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.NUMERO_INTEIRO">

        <ng-container *ngIf="pergunta?.resposta?.placeholder">
            <input type="text" enterkeyhint="next" inputmode="numeric" step="1" min="0" class="ipt" #ipt
                [placeholder]="pergunta?.resposta?.placeholder" ngModel
                (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />
        </ng-container>

        <ng-container *ngIf="!pergunta?.resposta?.placeholder">
            <input type="text" enterkeyhint="next" inputmode="numeric" step="1" min="0" class="ipt" #ipt ngModel
                (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />
        </ng-container>

    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.TEXTO">

        <ng-container *ngIf="pergunta?.resposta?.placeholder">

            <input enterkeyhint="next" type="text" class="ipt" [placeholder]="pergunta?.resposta?.placeholder" #ipt
                ngModel (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />
        </ng-container>

        <ng-container *ngIf="!pergunta?.resposta?.placeholder">
            <input enterkeyhint="next" type="text" class="ipt" #ipt ngModel
                (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />

        </ng-container>

    </ng-container>

    <input type="text" enterkeyhint="next" class="ipt" placeholder="Exemplo: 10/05/1960" mask="00/00/0000" #ipt
        inputmode="numeric" ngModel *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.DATA"
        (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />

    <input type="text" enterkeyhint="next" class="ipt" placeholder="(00) 00000-0000" mask="(00) 00000-0000" #ipt
        inputmode="numeric" ngModel *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.CELULAR"
        (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />

    <input type="email" enterkeyhint="next" class="ipt" placeholder="exemplo@exemplo.com " #ipt ngModel
        *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.EMAIL"
        (keyup)="teclaEnter($event,getClassBtnAvancar(pergunta?.id,pergunta?.identificador),ipt)" />


    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA">

        <ng-container *ngFor="let alternativa of pergunta?.resposta?.alternativas; let i = index" class="mt-5">
            <div class="base-btn-alternativa btn-alternativa-padrao mt-2"
                [ngClass]="getNomeClasseGrupo(pergunta?.identificador)" #btn data-selecionado="null"
                [id]="getNomeCampoAlternativa(i,pergunta?.identificador)"
                (click)="responderMultiplo(alternativa.titulo,getNomeCampoAlternativa(i,pergunta?.identificador),alternativa)">
                <span class="base-letra-alternativa letra-alternativa-padrao ms-1 me-1">{{getLetraAlternativa(i) |
                    uppercase}}</span>
                {{alternativa.titulo}}
            </div>
            {{alterarEstiloBtnAlternativa(alternativa,btn)}}
        </ng-container>

    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ARQUIVO">

        <label for="ipt-arquivo" class="btn-alternativa mt-3">
            <ng-container *ngIf="nomeArquivo == null">Escolher Arquivo</ng-container>
            <ng-container *ngIf="nomeArquivo != null">{{nomeArquivo}}</ng-container>
        </label>

        <input type="file" id="ipt-arquivo" style="display: none;" (change)="carregarArquivo(pergunta,$event)" #ipt
            ngModel>

    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.INFORMATIVO_VIDEO">

        <div class="box-video" [style]="getEstiloVideo()" [id]="getIdVideoBox(pergunta?.resposta?.id)">

            <youtube-player-layout-v1 [id]="getIdVideo(pergunta?.resposta?.id)" suggestedQuality="hd720"
                [width]="getLarguraVideo(pergunta?.resposta?.id)" [height]="getAlturaVideo(pergunta?.resposta?.id)"
                videoId="{{pergunta?.resposta?.linkVideo}}" style="width: 99.9% !important;"></youtube-player-layout-v1>

            <img src="{{pergunta?.resposta?.gifVideo}}" (click)="playVideoGif(pergunta?.resposta?.id)"
                *ngIf="showGifOverlay()" alt="Video">


        </div>

    </ng-container>

    <button type="button" class="btn-voltar mt-2 me-3" (click)="voltar()" *ngIf="mostrarBtnVoltar"><span
            class="me-1"></span>Voltar</button>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA
        && pergunta?.resposta?.tipoResposta != TipoResposta.INFORMATIVO_TEXTO
        && pergunta?.resposta?.tipoResposta != TipoResposta.INFORMATIVO_VIDEO
        && pergunta?.resposta?.tipoResposta != TipoResposta.MARCACAO_USUARIO && !pergunta?.ultima">

        <ng-container *ngIf="!pergunta?.botaoAvancar?.textoBotaoPersonalizado">

            <button type="button" class="btn-proximo mt-2"
                [ngClass]="getClassBtnAvancar(pergunta?.id,pergunta?.identificador)"
                (click)="responder(pergunta, input?.nativeElement.value)"
                *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_MULTIPLA">Avançar</button>

            <button type="button" class="btn-proximo mt-2" id="btn-avancar-multiplo"
                (click)="responder(pergunta, juntaRespostasMultiplo(respostasMultiplo))"
                *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA">Avançar</button>

        </ng-container>

        <ng-container *ngIf="pergunta?.botaoAvancar?.textoBotaoPersonalizado">

            <button type="button" class="btn-proximo mt-2"
                [ngClass]="getClassBtnAvancar(pergunta?.id,pergunta?.identificador)"
                (click)="responder(pergunta, input?.nativeElement.value)"
                *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_MULTIPLA">{{pergunta?.botaoAvancar?.textoBotao}}</button>

            <button type="button" class="btn-proximo mt-2" id="btn-avancar-multiplo"
                (click)="responder(pergunta, juntaRespostasMultiplo(respostasMultiplo))"
                *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA">{{pergunta?.botaoAvancar?.textoBotao}}</button>

        </ng-container>

    </ng-container>

    <ng-container
        *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_UNICA && pergunta?.resposta?.tipoResposta != TipoResposta.INFORMATIVO_TEXTO && pergunta?.resposta?.tipoResposta != TipoResposta.INFORMATIVO_VIDEO && pergunta?.ultima ">

        <ng-container *ngIf="!pergunta?.botaoAvancar?.textoBotaoPersonalizado">

            <button type="button" class="btn-proximo mt-2 btn-aparecer"
                [ngClass]="getClassBtnAvancar(pergunta?.id,pergunta?.identificador)"
                (click)="responder(pergunta, input?.nativeElement.value)"
                *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_MULTIPLA">Finalizar</button>

            <button type="button" class="btn-proximo mt-2" id="btn-avancar-multiplo"
                (click)="responder(pergunta, juntaRespostasMultiplo(respostasMultiplo))"
                *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA">Finalizar</button>

        </ng-container>

        <ng-container *ngIf="pergunta?.botaoAvancar?.textoBotaoPersonalizado">

            <button type="button" class="btn-proximo mt-2 btn-aparecer"
                [ngClass]="getClassBtnAvancar(pergunta?.id,pergunta?.identificador)"
                (click)="responder(pergunta, input?.nativeElement.value)"
                *ngIf="pergunta?.resposta?.tipoResposta != TipoResposta.ALTERNATIVA_MULTIPLA">{{pergunta?.botaoAvancar?.textoBotao}}</button>

            <button type="button" class="btn-proximo mt-2" id="btn-avancar-multiplo"
                (click)="responder(pergunta, juntaRespostasMultiplo(respostasMultiplo))"
                *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.ALTERNATIVA_MULTIPLA">{{pergunta?.botaoAvancar?.textoBotao}}</button>

        </ng-container>

    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.INFORMATIVO_TEXTO">
        <ng-container *ngIf="!pergunta?.botaoAvancar?.textoBotaoPersonalizado">
            <button type="button" class="btn-proximo mt-2"
                [ngClass]="[getClassBtnAvancar(pergunta?.id,pergunta?.identificador), classeBtn()]"
                (click)="responder(pergunta, input?.nativeElement.value)">Continuar</button>
        </ng-container>
        <ng-container *ngIf="pergunta?.botaoAvancar?.textoBotaoPersonalizado">
            <button type="button" class="btn-proximo mt-2"
                [ngClass]="[getClassBtnAvancar(pergunta?.id,pergunta?.identificador), classeBtn()]"
                (click)="responder(pergunta, input?.nativeElement.value)">{{pergunta?.botaoAvancar?.textoBotao}}</button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="pergunta?.resposta?.tipoResposta == TipoResposta.INFORMATIVO_VIDEO">

        <ng-container *ngIf="!pergunta?.botaoAvancar?.textoBotaoPersonalizado">
            <button type="button" [id]="getIdVideoBotao(pergunta?.identificador)" class="btn-proximo mt-2"
                [ngClass]="[getClassBtnAvancar(pergunta?.id,pergunta?.identificador), classeBtn()]"
                (click)="responderVideo(pergunta?.resposta?.id, pergunta)">Continuar</button>
        </ng-container>
        <ng-container *ngIf="pergunta?.botaoAvancar?.textoBotaoPersonalizado">
            <button type="button" [id]="getIdVideoBotao(pergunta?.identificador)" class="btn-proximo mt-2"
                [ngClass]="[getClassBtnAvancar(pergunta?.id,pergunta?.identificador), classeBtn()]"
                (click)="responderVideo(pergunta?.resposta?.id,pergunta)">{{pergunta?.botaoAvancar?.textoBotao}}</button>
        </ng-container>
    </ng-container>

</div>