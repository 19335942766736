<layout-v2-title [title]="pergunta.titulo" [idPergunta]="pergunta.id"></layout-v2-title>

<layout-v2-image *ngIf="pergunta.exibeImagem" [heightBoxImage]="pergunta.alturaBoxImagem"
    [marginTopBoxImagem]="pergunta.margemTopBoxImagem" [imagemBase64]="pergunta.imagemBase64"
        [showTextImage]="pergunta.exibeTextoImagem" [textImage]="pergunta.textoImagem"></layout-v2-image>

<layout-v2-description [description]="pergunta.descricao" [idPergunta]="pergunta.id"></layout-v2-description>

<ng-container *ngFor="let a of pergunta.resposta.alternativas; let i = index">

    <layout-v2-button type="0" [title]="a.titulo" (clicked)="onResponse(a)"
        [isSelection]="isSelectedButton(a.id)"
        [letter]="getLetterAlternative(i)"
        [styleButton]="a.estilo"
        [ownStyle]="isOwnerStyle(a)"
        [id]="a.id"
        [styleButton]="a.estilo"
        [showLetter]="pergunta.resposta.exibirLetraAlternativa"
        ></layout-v2-button>

</ng-container>

<layout-v2-button type="2" *ngIf="pergunta.exibirBtnWhatsApp" (clicked)="onAnswered()"></layout-v2-button>

<ng-container *ngIf="!pergunta.exibirBtnWhatsApp">

    <ng-container *ngIf="pergunta.personalizarBtnAvancar">
        <layout-v2-button type="3"
            [id]="pergunta.botaoAvancar.id"
            [showLetter]="false"
            [ownStyle]="true"
            [styleButton]="pergunta.botaoAvancar"
            [title]="pergunta.botaoAvancar.textoBotaoPersonalizado ? pergunta.botaoAvancar.textoBotao : 'Continuar !'"
            (clicked)="onAnswered()"></layout-v2-button>
    </ng-container>

    <ng-container *ngIf="!pergunta.personalizarBtnAvancar">
        <layout-v2-button type="3" title="Continuar" (clicked)="onAnswered()"></layout-v2-button>
    </ng-container>

</ng-container>

<layout-v2-button *ngIf="enableBackButton" type="1" (clicked)="onBack()"></layout-v2-button>

