import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ViewComponent } from "./view.component";
import { LayoutV1Module } from "./layouts/layout-v1/layout-v1.module";
import { ArvorePerguntaService } from "./services/arvore-pergunta.service";
import { ObservablesService } from "./services/obervables.service";
import { ViewMensagemService } from "./services/view-mensagem.service";
import { PassadorService } from "./services/passador.service";
import { LayoutV2Module } from "./layouts/layout-v2/layout-v2.module";

@NgModule({
    declarations: [
        ViewComponent
    ],
    imports: [
        CommonModule,
        LayoutV1Module,
        LayoutV2Module
    ],
    exports: [
        ViewComponent
    ],
    providers: [
        ArvorePerguntaService,
        ObservablesService,
        ViewMensagemService,
        PassadorService
    ]
})
export class ViewModule{

}
