import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeHtml } from "@angular/platform-browser";
import { ReplaySubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { EstiloFormulario } from "src/app/models/estilo-formulario.model";

import { CampoFormularioService } from "./campo-formulario.service";
import { YoutubePlayerService } from "./youtube-player/youtube-player-service";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v1-campo-formulario',
    templateUrl: './campo-formulario.component.html',
    styleUrls: ['./campo-formulario.component.scss'],
})
export class CampoFormularioComponent implements OnInit, OnChanges {

    @Input()
    public estilo!: EstiloFormulario | undefined;

    @Input()
    public pergunta: Pergunta | undefined;

    @Input()
    public mostrarBtnVoltar?: boolean = true;

    @Output()
    public resposta: EventEmitter<RespostaUsuario> = new EventEmitter<any>();

    @Output()
    public voltarPergunta: EventEmitter<void> = new EventEmitter<void>();

    public TipoResposta = TipoResposta;

    private letras: string[] = ['a', 'b', 'c', 'd', 'e'];

    @ViewChild("ipt") input: ElementRef | ElementRef<HTMLInputElement> | undefined;

    public respostasMultiplo: string[] = [];

    public nomeArquivo: string | null = null;

    public isNavegadorChrome: boolean = false;

    private arquivoBase64!: string;

    constructor(private sinitizer: DomSanitizer,
        private campoFormularioService: CampoFormularioService,
        private sanitizer: DomSanitizer,
        private youtubePlayerService: YoutubePlayerService
    ) { }

    ngOnInit(): void {

        let navegador = window.navigator.userAgent;

        if (navegador.indexOf("Chrome") > -1) {
            this.isNavegadorChrome = true;
        }

        if (this.pergunta?.ultima) this.efeitoBtnUltimo();

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.estiloFormulario();
    }

    public teclaEnter(event: KeyboardEvent, classe: string, campo: HTMLInputElement) {
        if (event.key == "Enter") {
            let btn = document.querySelector(`.${classe}`) as HTMLButtonElement;
            btn.click();
            campo.blur();
        }
    }

    public getHtml(pergunta: string | null | undefined): SafeHtml {
        let safeHtml: SafeHtml;
        pergunta = pergunta as string;
        safeHtml = this.sanitizer.bypassSecurityTrustHtml(pergunta);

        return safeHtml;

    }

    public getPerguntaFormatada(pergunta: string | null | undefined): string {

        pergunta = pergunta as string;

        if (pergunta != null && pergunta.match("<span class='destaque-variavel'>")) {

            let indexInicio = pergunta.indexOf("<span class='destaque-variavel'>");

            let indexFinal = pergunta.indexOf("</span>");

            let chave = pergunta.substring(indexInicio + 32, indexFinal);

            let variavel = sessionStorage.getItem(chave) as string;

            pergunta = pergunta.replace(`<span class='destaque-variavel'>${chave}</span>`, variavel);

            return pergunta;

        }


        return pergunta;
    }

    public getLetraAlternativa(index: number) {
        return this.letras[index];
    }

    public responder(pergunta: Pergunta | undefined, resposta: Alternativa | Alternativa[] | string | string[] | undefined) {
        if (pergunta?.resposta.tipoResposta == TipoResposta.ARQUIVO) resposta = this.arquivoBase64;
        this.resposta.emit({ pergunta: pergunta, resposta: resposta } as RespostaUsuario);
    }

    public responderVideo(id: number | null | undefined, pergunta: Pergunta | undefined) {
        let nomeVideo = this.getIdVideo(id);
        this.youtubePlayerService.pause();
        let videoAssistido = this.campoFormularioService.videoAssistido(nomeVideo);
        let videoClicado = this.campoFormularioService.videoClicado(nomeVideo);
        this.resposta.emit({ pergunta: pergunta, resposta: `${videoClicado};${videoAssistido}` } as RespostaUsuario);
    }

    public voltar() {
        this.voltarPergunta.emit();
    }

    public getNomeCampoAlternativa(index: number, campo: string | undefined): string {
        return `campo_${campo}_${index}`;
    }

    public getNomeClasseGrupo(campo: string | undefined) {
        return `grupo_${campo}`;
    }

    public responderUnico(pergunta: Pergunta | undefined, resposta: Alternativa,
        idAlternativa: string, classeGrupo: string, alternativa?: Alternativa) {

        let alternativas = document.querySelectorAll(`.${classeGrupo}`);

        alternativas.forEach((alt, index) => {
            if (alt.classList.contains('btn-alternativa-personalizado-selecionado')) {
                alt.classList.remove('btn-alternativa-personalizado-selecionado');
                this.estiloAlternativaNaoSelecionado(alt as HTMLDivElement, (this.pergunta?.resposta.alternativas as Alternativa[])[index]);
                (alt as HTMLDivElement).dataset['selecionado'] = 'false';
            } else {
                alt.classList.remove('btn-alternativa-selecionado');
                alt.querySelector('.base-letra-alternativa')?.classList.remove('letra-alternativa-selecionada');
            }
        });

        let btnAlternativa = document.querySelector(`#${idAlternativa}`) as HTMLDivElement;

        if (alternativa?.estiloProprio) {

            let selecionado = btnAlternativa.dataset['selecionado'];
            if (selecionado == 'true') {
                this.estiloAlternativaNaoSelecionado(btnAlternativa, alternativa);
                btnAlternativa.dataset['selecionado'] = 'false';
                btnAlternativa.classList.remove('btn-alternativa-personalizado-selecionado');
            } else {
                this.estiloAlternativaSelecionado(btnAlternativa, alternativa);
                btnAlternativa.dataset['selecionado'] = 'true';
                btnAlternativa.classList.add('btn-alternativa-personalizado-selecionado');
            }

        } else {
            btnAlternativa?.classList.add('btn-alternativa-selecionado');
            btnAlternativa?.querySelector('.base-letra-alternativa')?.classList.add('letra-alternativa-selecionada');
        }

        this.responder(pergunta, resposta);

    }

    public responderMultiplo(resposta: string, idBtnOpcao: string, alternativa: Alternativa) {

        let resp = this.respostasMultiplo.find(r => r === resposta);

        if (resp == undefined) {
            this.respostasMultiplo.push(resposta);
        } else {
            let index = this.respostasMultiplo.indexOf(resp);
            this.respostasMultiplo.splice(index, 1);
        }

        let btnAlternativa = document.querySelector(`#${idBtnOpcao}`) as HTMLDivElement;

        if (alternativa.estiloProprio) {

            let selecionado = btnAlternativa.dataset['selecionado'];
            if (selecionado == 'true') {
                this.estiloAlternativaNaoSelecionado(btnAlternativa, alternativa);
                btnAlternativa.dataset['selecionado'] = 'false';
            } else {
                this.estiloAlternativaSelecionado(btnAlternativa, alternativa);
                btnAlternativa.dataset['selecionado'] = 'true';
            }

        } else {

            if (btnAlternativa.classList.contains('btn-alternativa-selecionado')) {
                btnAlternativa.classList.remove('btn-alternativa-selecionado');
                btnAlternativa.querySelector(".base-letra-alternativa")?.classList.remove('letra-alternativa-selecionada');
            } else {
                btnAlternativa.classList.add('btn-alternativa-selecionado');
                btnAlternativa.querySelector(".base-letra-alternativa")?.classList.add('letra-alternativa-selecionada');
            }

        }



    }

    public juntaRespostasMultiplo(respostas: string[]): string {
        let resp = "";
        respostas.forEach(resposta => {
            resp += resposta + ",";
        });
        return resp;
    }

    public carregarArquivo(pergunta: Pergunta | undefined, evento: any) {

        this.nomeArquivo = evento.target.files[0].name as string;

        this.convertFile(evento.target.files[0]).subscribe({
            next: arquivoBase64 => {
                this.arquivoBase64 = arquivoBase64;
            },
            error: error => { }
        });

    }

    private convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(window.btoa(event.target?.result?.toString() as string));
        return result;
    }

    public getClassBtnAvancar(id: number | undefined, campo: string | undefined): string {
        return `btnAvancar_${campo}_${id}`;
    }

    public confereURLVideo(url: string | undefined): SafeResourceUrl {
        url = url as string;
        return this.sinitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getIdVideoBotao(identificador: string | null | undefined): string {
        let idCompleto = '';
        if (identificador != null) {
            idCompleto = "ID_VIDEO_" + identificador;
        }

        this.campoFormularioService.addVideo(idCompleto);
        return idCompleto;
    }

    public getIdVideo(id: number | null | undefined): string {
        let idCompleto = '';
        if (id != null) {
            idCompleto = "ID_VIDEO_" + id;
        }

        this.campoFormularioService.addVideo(idCompleto);
        return idCompleto;
    }

    public getPathVideo(nome: string | null | undefined): string {
        return `${nome}`;
    }

    public fimVideo(id: number | null | undefined, event: any) {
        let idCompleto = this.getIdVideo(id);
        this.campoFormularioService.setVideoAssistido(idCompleto);
    }

    public playVideo(id: number | null | undefined, event: any) {
        let idCompleto = this.getIdVideo(id);
        this.campoFormularioService.setVideoPlay(idCompleto);
    }

    videoPlaying: boolean = false;

    showGifOverlay(): boolean {
        return !this.videoPlaying && this.pergunta?.resposta?.gifVideo != undefined && this.pergunta?.resposta?.gifVideo != '';
    }



    public getIdVideoBox(id: number | null | undefined) {
        return this.getIdVideo(id) + "_box";
    }

    public getLarguraVideo(id: number | null | undefined) {
        let idCompleto = this.getIdVideoBox(id);
        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;
        const estiloComputado = window.getComputedStyle(elementoPai);
        let largura = parseInt(estiloComputado.width, 10);
        return largura;
    }

    public getAlturaVideo(id: number | null | undefined) {
        let idCompleto = this.getIdVideoBox(id);
        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;
        const estiloComputado = window.getComputedStyle(elementoPai);
        let altura = parseInt(estiloComputado.height, 10);
        return altura;
    }


    public playVideoGif(id: number | null | undefined) {

        let idCompleto = this.getIdVideo(id);
        let idIframe = '';

        let videoPlayer = undefined;

        let elementoPai = document.getElementById(idCompleto.trim()) as HTMLElement;

        for (let i = 0; i < elementoPai.children.length; i++) {
            const elementoFilho = elementoPai.children[i];
            if (elementoFilho.tagName === 'IFRAME') {
                const elementoFilho = elementoPai.children[i];
                videoPlayer = elementoFilho as HTMLElement;
                videoPlayer.style.width = elementoPai.style.width;
                videoPlayer.style.height = elementoPai.style.height;
                idIframe = elementoFilho.id;

                break;
            }
        }

        this.campoFormularioService.setVideoPlay(idCompleto);

        if (videoPlayer && !this.videoPlaying) {
            if (this.pergunta?.resposta.fullScreen) {
                this.iniciarTelaInteira(videoPlayer, this.pergunta?.resposta.tempoFullScreen);
            }
            this.youtubePlayerService.play();

        } else {
            this.youtubePlayerService.pause();
        }

        this.videoPlaying = !this.videoPlaying;

    }
    private intervaloExitFullScreen: any;

    iniciarTelaInteira(videoElement: HTMLElement, tempo: number) {
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        }
        if (tempo == undefined) {
            let tempoTotal = this.youtubePlayerService.getTempoTotal();
            if (tempoTotal) {
                tempo = tempoTotal;
            }
        }

        if (tempo != undefined) {
            this.intervaloExitFullScreen = setInterval(() => {
                let tempoAtual = this.youtubePlayerService.getTempoAtual();
                if (tempoAtual) {
                    if (tempoAtual >= tempo) {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                            clearInterval(this.intervaloExitFullScreen);
                        }
                    }
                }
            }, 2000);
        }

    }

    public fecharTecladoVirtual() {
        let btn = document.querySelector("#escapeTeclado") as HTMLInputElement;
        btn.focus();
    }

    public imgBase64(): string {
        return `data:image/jpeg;base64,${this.pergunta?.imagemBase64}`;
    }

    public confereHTML() {
        let codigoCorrigido = this.sinitizer.bypassSecurityTrustHtml(this.pergunta?.codigoHTML as string);
        return codigoCorrigido;
    }

    //Faz o ultimo btn ficar "piscando" na tela
    private efeitoBtnUltimo() {

        setInterval(() => {

            let classEsconde = "btn-esconder";
            let classAparece = "btn-aparecer";

            let btnAparecer = document.getElementsByClassName(classAparece)[0];
            let btnEsconder = document.getElementsByClassName(classEsconde)[0];

            if (btnAparecer) {
                btnAparecer.classList.remove(classAparece);
                btnAparecer.classList.add(classEsconde);
            } else if (btnEsconder) {
                btnEsconder.classList.remove(classEsconde);
                btnEsconder.classList.add(classAparece);
            }

        }, 900);
    }

    public classeBtn(): string {
        if (!this.pergunta?.ultima) {
            return "";
        }
        return "btn-aparecer";
    }

    public alterarEstiloBtnAlternativa(alternativa: Alternativa, btn: HTMLDivElement) {
        if (alternativa.estiloProprio) {
            let selecionado = btn.dataset['selecionado'];
            if (selecionado == "null") {
                this.estiloAlternativaNaoSelecionado(btn, alternativa);
            }
        }
    }

    private estiloAlternativaNaoSelecionado(btn: HTMLDivElement, alternativa: Alternativa) {
        if (alternativa.estiloProprio) {
            btn.classList.remove('btn-alternativa-padrao');
            btn.style.backgroundColor = alternativa?.estilo?.corFundo as string;
            btn.style.color = alternativa?.estilo?.corTitulo as string;
            btn.style.border = `1px solid ${alternativa.estilo?.corBorda as string}`;
            let letraBtn = btn.getElementsByClassName('base-letra-alternativa')[0] as HTMLSpanElement;
            letraBtn.classList.remove('letra-alternativa-padrao');
            letraBtn.style.backgroundColor = alternativa.estilo?.corFundoLetra as string;
            letraBtn.style.color = alternativa.estilo?.corLetra as string;
            letraBtn.style.border = `1px solid ${alternativa.estilo?.corBordaLetra as string}`;
        }
    }

    private estiloAlternativaSelecionado(btn: HTMLDivElement, alternativa: Alternativa) {
        if (alternativa.estiloProprio) {
            btn.style.backgroundColor = alternativa.estilo?.corFundoSelecionado as string;
            btn.style.color = alternativa.estilo?.corTituloSelecionada as string;
            btn.style.border = `1px solid ${alternativa.estilo?.corBordaSelecionada as string}`;
            let letraBtn = btn.querySelector(".base-letra-alternativa") as HTMLSpanElement;
            letraBtn.style.backgroundColor = alternativa.estilo?.corFundoLetraSelecionado as string;
            letraBtn.style.color = alternativa.estilo?.corLetraSelecionada as string;
            letraBtn.style.border = `1px solid ${alternativa.estilo?.corBordaLetraSelecionada as string}`;
        }
    }

    private estiloFormulario() {

        if (this.estilo) {

            let containers = document.getElementsByClassName("container-pergunta") as HTMLCollectionOf<HTMLDivElement>;

            for (let i = 0; i < containers.length; i++) {

                let div = containers.item(i) as HTMLDivElement;

                div.style.fontFamily = `${this.estilo.fonte}`;
                div.style.color = this.estilo.corFonte;
                if (!this.estilo?.exibeImagemFundo) {
                    div.style.backgroundColor = this.estilo.corFundo;
                } else {
                    div.style.background = "transparent";
                }

                let fInputs = (inputs: NodeListOf<HTMLInputElement>) => {
                    inputs.forEach(input => {
                        input.style.color = this.estilo?.corFonte as string;
                        input.style.fontFamily = this.estilo?.fonte as string;
                        if (!this.estilo?.exibeImagemFundo) {
                            input.style.backgroundColor = this.estilo?.corFundo as string;
                        } else {
                            input.style.background = "transparent";
                        }
                    });
                }

                fInputs(div.querySelectorAll("input[type='text']") as NodeListOf<HTMLInputElement>);
                fInputs(div.querySelectorAll("input[type='email']") as NodeListOf<HTMLInputElement>);

            }

        }
    }

    public getImagem(): string{
        return `data:image/png;base64,${this.pergunta?.imagemBase64}`;
    }

    public getEstiloVideo(): string {
        let altura = this.pergunta?.alturaBoxVideo == null ? '320px' : `${this.pergunta?.alturaBoxVideo}px`;
        let estilo = `position: relative; width: 100%; max-height: 220px; height: ${altura} !important; margin-top: 25px !important;`;
        return estilo;
    }

    public getEstiloHtml(): string {
        let altura = this.pergunta?.alturaBoxHtml == null ? '320px' : `${this.pergunta?.alturaBoxHtml}px`;
        let margem = this.pergunta?.margemTopBoxHtml == null ? '0px' : `${this.pergunta?.margemTopBoxHtml}px`;
        let estilo = `position: relative; width: 100%; max-height: 220px; height: ${altura} !important; margin-top: ${margem} !important;`;
        return estilo;
    }

    public retirarLista(text: string | null | undefined): string {
        if (text && text.match("<ul>")) {
            let firstIndex = text.indexOf("<ul>");
            let lastIndex = text.indexOf("</ul>");
            let firstPart = text.substring(0, firstIndex);
            let lastPart = text.substring(lastIndex + 4, text.length - 1);
            text = `${firstPart}${lastPart}`;
        }
        return text as string;
    }

    public getPartesLista(text: string | null | undefined) {

        if (!text || !text.match("<ul>")) return [];

        let parts: string[] = [];

        while (text.match("<li>")) {

            let firstIndex = text.indexOf("<li>");
            let lastIndex = text.indexOf("</li>");

            let item = text.substring(firstIndex + 4, lastIndex);

            parts.push(item);

            text = text.replace("<li>", "").replace("</li>", "");

        }

        return parts;

    }

}
