<div style="margin-top: 15%; margin-bottom: 10%;">
    <h4 style="margin-bottom: 5px">{{titulo}}</h4>
    <mat-form-field style="width: 100%;">
        <mat-label>{{placeholder}}</mat-label>
        <mat-select (selectionChange)="onAlternativaSelecionada($event)" style="overflow-y: visible !important;">
            <ng-container *ngFor="let alternativa of alternativas">
                <mat-option [value]="alternativa" style="font-size: 20px;">{{alternativa | uppercase}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <div class="msg-erro" *ngIf="exibirErro">
        <div>
            <mat-icon aria-hidden="false" aria-label="Example home icon">error</mat-icon>
        </div>
        <div>
            <span>{{msgErro}}</span>
        </div>
    </div>
</div>
