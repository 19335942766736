<layout-v2-title [title]="pergunta.titulo" [idPergunta]="pergunta.id"></layout-v2-title>

<layout-v2-image *ngIf="pergunta.exibeImagem" [heightBoxImage]="pergunta.alturaBoxImagem"
    [marginTopBoxImagem]="pergunta.margemTopBoxImagem" [imagemBase64]="pergunta.imagemBase64"
        [showTextImage]="pergunta.exibeTextoImagem" [textImage]="pergunta.textoImagem"></layout-v2-image>


<layout-v2-description [description]="pergunta.descricao" [idPergunta]="pergunta.id"></layout-v2-description>

<layout-v2-dropdown [titulo]="pergunta.tituloCampo" [placeholder]="pergunta.resposta.placeholder"
    [msgErro]="getMsgErro()" [exibirErro]="exibirMsgErro"
    [alternativas]="converteAlternativa()" (alternativaSelecionada)="onOpcaoSelecionada($event)"></layout-v2-dropdown>

<ng-container *ngIf="pergunta.personalizarBtnAvancar">
    <layout-v2-button type="3"
        [id]="pergunta.botaoAvancar.id"
        [showLetter]="false"
        [ownStyle]="true"
        [styleButton]="pergunta.botaoAvancar"
        [trocarCor]="mudarCorBtn"
        [title]="pergunta.botaoAvancar.textoBotaoPersonalizado ? pergunta.botaoAvancar.textoBotao : 'Continuar !'"
        (clicked)="onAnswered()"></layout-v2-button>
</ng-container>

<ng-container *ngIf="!pergunta.personalizarBtnAvancar">
    <layout-v2-button type="3" title="Continuar" (clicked)="onAnswered()"></layout-v2-button>
</ng-container>

<layout-v2-button *ngIf="enableBackButton" type="1" (clicked)="onBack()"></layout-v2-button>