import { Component, DoCheck, Input } from '@angular/core';
import { FormularioOpcao } from "src/app/models/formulario-opcao.model";

@Component({
    selector: 'layout-v1-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements DoCheck {

    @Input()
    public corFundo!: string | undefined;

    @Input()
    public estilo!: FormularioOpcao | undefined;

    constructor() {}

    ngDoCheck(): void {
        if(this.estilo?.exibeRodape) this.aplicarImagem();
    }

    private aplicarImagem(){

        const imgRodape = document.querySelector("#imgRodape") as HTMLDivElement;

        let urlImg = "url('../../../assets/imgs/google.jpg')";

        if(this.estilo != null && this.estilo.imagemRodapeBase64 ) {
            urlImg = `url(data:image/png;base64,${this.estilo.imagemRodapeBase64})`;
        }

        if(imgRodape) imgRodape.style.backgroundImage = urlImg;

    }

}
