<ng-container *ngIf="liberarTela">

    <layout-v1 *ngIf="versaoLayout == 'v1'"
        [formulario]="formulario" [perguntas]="perguntas"
        (eventoResponder)="onResponder($event)" (eventoVoltar)="onVoltar()"></layout-v1>

    <layout-v2 *ngIf="versaoLayout == 'v2'"
        [formulario]="formulario" [perguntas]="perguntas"
        (eventoResponder)="onResponder($event)" (eventoVoltar)="onVoltar()" 
        (eventoVideo)="onEventVideo($event)" ></layout-v2>

</ng-container>
