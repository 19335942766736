import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v2-assembler-date',
    templateUrl: './assembler-date.component.html',
    styleUrls: ['./assembler-date.component.scss']
})
export class AssemblerDateComponent{

    @Input()
    public pergunta!: Pergunta;

    @Input()
    public enableBackButton: boolean = true;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    private valueInput: string="";

    constructor(){}

    public onAnswered(){
        let resposta = {
            pergunta: this.pergunta,
            resposta: this.valueInput
        } as RespostaUsuario;
        this.answered.emit(resposta);
    }

    public onBack(){
        this.back.emit();
    }

    public onValueInput(value: string){
        this.valueInput = value;
    }


}
