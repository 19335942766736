import { Component, Input, DoCheck, AfterViewInit } from "@angular/core";
import { ComponentsService } from "../components.service";

@Component({
    selector: 'layout-v2-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements DoCheck, AfterViewInit{

    @Input()
    public description!: string | null;

    @Input()
    public idPergunta!: number;

    public list: string[] = [];

    constructor(
        private componentsService: ComponentsService
    ){}

    ngDoCheck(): void {
        this.definirDescricao();
    }

    ngAfterViewInit(): void {
        this.definirDescricao();
    }

    private definirDescricao(){
        if(this.description){
            let desc = document.querySelector(`#${this.getIdDescription()}`) as HTMLParagraphElement;
            if(!desc) return;
            this.list = this.componentsService.getListParts(this.description);
            let newText = this.componentsService.replaceVariable(this.description);
            newText = this.componentsService.formatText(newText);
            desc.innerHTML = newText;
        }
    }

    public getIdDescription(){
        return `desc-${this.idPergunta}`;
    }

}
