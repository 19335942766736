export interface Validacao {
    tipo: TipoValidacao;
    valor: string;
    mensagem: string;
}

export enum TipoValidacao {
    REQUERIDA = "Requerida",
    VALOR_MINIMO = "Valor Mínimo",
    VALOR_MÁXIMO = "Valor Máximo",
    ALFANUMERICO = "Alfanúmerico"
}
