import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { EstiloFormulario } from "src/app/models/estilo-formulario.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";
import { TipoResposta } from "src/app/models/tipo-resposta.model";
import { PassadorService } from "src/app/view/services/passador.service";

@Component({
    selector: 'layout-v2-assembler',
    templateUrl: './assembler.component.html',
    styleUrls: ['./assembler.component.scss']
})
export class AssemblerComponent implements OnChanges{

    @Input()
    public perguntas: Pergunta[] = [];

    @Input()
    public estilo!: EstiloFormulario | undefined;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public eventVideo:EventEmitter<object> = new EventEmitter<object>();

    public tipoResposta = TipoResposta;

    private valorAvanco = 0;

    private passarPergunta: boolean = true;

    public subRespostaSalvas:Subject<Map<string,string>> = new Subject<Map<string, string>>();

    constructor(
        private passadorService: PassadorService
    ){}

    ngOnChanges(changes: SimpleChanges): void {
        if(this.perguntas){
            let sessao = document.querySelector('.layout-v2-section') as HTMLElement;
            if(sessao) {
                sessao.style.width = `${this.perguntas.length * 100}%`;
                sessao.style.marginRight = "24px";
            }
        }
        this.passadorService.getPassador().subscribe({
            next: valor => this.passarPergunta = valor
        });
    }

    public onAnswered(response: RespostaUsuario){
        this.answered.emit(response);
        if(this.passarPergunta == true) this.avancarPergunta();
    }

    private avancarPergunta(){
        let container = document.querySelector('.layout-v2-section') as HTMLElement;
        if(container) {
            this.valorAvanco += 100;
            container.style.marginLeft = `-${this.valorAvanco}%`;
        }
    }

    private retrocederPergunta(){
        let container = document.querySelector('.layout-v2-section') as HTMLElement;
        if(container) {
            if(this.valorAvanco >= 100) this.valorAvanco -= 100;
            container.style.marginLeft = `-${this.valorAvanco}%`;
        }
    }

    public onBack(){
        this.retrocederPergunta();
        this.back.emit();
    }

    public onEventVideo(dado: object){
        this.eventVideo.emit(dado);
    }


}
