import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ControllerService } from "./services/controller.service";
import { Formulario } from "../models/formulario.model";
import { RespostaUsuario } from "../models/resposta-usuario.model";
import { Subscription } from "rxjs";
import { Burlador } from "./models/burlador.model";
import { BurladorService } from "./services/burlador.service";

@Component({
    selector: 'app-controller',
    templateUrl: 'controller.component.html',
    styleUrls: ['./controller.component.scss']
})
export class ControllerComponent implements OnInit, OnDestroy {

    public formulario!: Formulario;
    public burladores: Burlador[] = [];

    private formulario$!: Subscription;
    private burladores$!: Subscription;

    constructor(
        private activetedRoute: ActivatedRoute,
        private controllerService: ControllerService,
        private burladorService: BurladorService
    ) { }

    ngOnInit(): void {
        this.controllerService.configuracoesIniciais();
        this.formulario$ = this.controllerService.buscarFormulario(this.activetedRoute)
            .subscribe({
                next: form => {
                    this.formulario = form;
                    this.burladores$ = this.burladorService.buscarBurladores(this.formulario.id).subscribe({
                        next: lista => {
                            this.burladores = lista
                        }
                    });
                }
            });
        
    }

    public eventoResponder(resposta: RespostaUsuario) {
        this.controllerService.tratarResposta(resposta, this.burladores);
    }

    public eventoVoltar(evento: any) { }

    ngOnDestroy(): void {
        this.formulario$.unsubscribe();
        this.burladores$.unsubscribe();
    }

    public onEventoVideo(dado: any) {
        this.controllerService.tratarEventoVideo(dado);
    }

}
