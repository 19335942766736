import { Injectable } from "@angular/core";
import { last } from "rxjs";

@Injectable()
export class ComponentsService{

    constructor(){}


    public formatText(text: string | null | undefined): string {

        text = text as string;

        if(text != null && text.match("<ul>")){
            let firstIndex = text.indexOf("<ul>");
            let lastIndex = text.indexOf("</ul>");
            let firstPart = text.substring(0,firstIndex);
            let lastPart = text.substring(lastIndex+4,text.length-1);
            text = `${firstPart}${lastPart}`;
        }

        while (text != null && text.match("<b>")) {
            text = this.replaceWord("<b>","</b>",3,`<span style='color:#3C3A80;font-weight: 600'>`,"</span>",text);
        }

        while(text != null && text.match("<i>")) {
            text = this.replaceWord("<i>","</i>",3,"","",text);
        }

        while(text != null && text.match("<u>")) {
            text = this.replaceWord("<u>","</u>",3,"","",text);
        }

        return text;

    }

    private replaceWord(startTag: string, finalTag: string, stepTag: number,
        startTagReplace: string, finalTagReplace: string, word: string): string{

        if (word != null && word.match(startTag)) {

            let firstIndex = word.indexOf(startTag);
            let lastIndex = word.indexOf(finalTag);

            let key = word.substring(firstIndex + stepTag, lastIndex);

            word = word.replace(`${startTag}${key}${finalTag}`,
                `${startTagReplace}${key}${finalTagReplace}`);


            return word;

        }

        return word;

    }

    public getListParts(text: string): string[]{
        if(!text.match("<ul>")) return [];

        let parts: string[] = [];

        while(text.match("<li>")){

            let firstIndex = text.indexOf("<li>");
            let lastIndex = text.indexOf("</li>");

            let item = text.substring(firstIndex + 4, lastIndex);

            parts.push(item);

            text = text.replace("<li>","").replace("</li>","");

        }

        return parts;

    }

    public replaceVariable(text: string): string {

        while (text.match("<span class='destaque-variavel'>")) {

            let firstIndex = text.indexOf("<span class='destaque-variavel'>");

            let lastIndex = text.indexOf("</span>");

            let textBefore = text.substring(0,firstIndex);

            let textAfter = text.substring(lastIndex+7,text.length);

            let key = text.substring(firstIndex+32,lastIndex);

            let variable = sessionStorage.getItem(key) as string;

            text = `${textBefore}${variable}${textAfter}`;

        }

        return text;

    }


}
