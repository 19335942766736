import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Alternativa } from "src/app/models/alternativa.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v2-assembler-multiple-type',
    templateUrl: './assembler-multiple-type.component.html',
    styleUrls: ['./assembler-multiple-type.component.scss']
})
export class AssemblerMultipleTypeComponent implements OnChanges{

    @Input()
    public pergunta!: Pergunta;

    @Input()
    public enableBackButton: boolean = true;

    @Output()
    public answered: EventEmitter<RespostaUsuario> = new EventEmitter<RespostaUsuario>();

    @Output()
    public back: EventEmitter<void> = new EventEmitter<void>();

    private alternativas:string[] = ['a','b','c','d','e','f'];

    private responses: Map<number,string> = new Map<number,string>();

    public isSelectedButtons: Map<number,boolean> = new Map<number,boolean>();

    constructor(){}

    ngOnChanges(changes: SimpleChanges): void {
        if(this.pergunta){
            this.pergunta.resposta.alternativas?.forEach(alternativa => {
                this.isSelectedButtons.set(alternativa.id,false);
            });
        }
    }

    public getLetterAlternative(index: number):string{
        return this.alternativas[index];
    }

    public onResponse(alternativa: Alternativa){
        if(this.responses.get(alternativa.id)) {
            this.responses.delete(alternativa.id);
            this.isSelectedButtons.set(alternativa.id,false);
        }else{
            this.responses.set(alternativa.id,alternativa.titulo);
            this.isSelectedButtons.set(alternativa.id,true);
        }
    }

    public onAnswered(){
        let response: string = "";
        this.responses.forEach((value,key) => {
            if(key != 0) response += ",";
            response += `${value}`;
        })
        let resposta = {
            pergunta: this.pergunta,
            resposta: response
        } as RespostaUsuario;
        this.answered.emit(resposta);
    }

    public onBack(){
        this.back.emit();
    }

    public isSelectedButton(idAlternative: number): boolean{
        return this.isSelectedButtons.get(idAlternative) as boolean;
    }

    public isOwnerStyle(alternative: Alternativa): boolean{
        if(alternative?.estilo) return true;
        return false;
    }

}
