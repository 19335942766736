import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FormularioOpcao } from "src/app/models/formulario-opcao.model";
import { Formulario } from "src/app/models/formulario.model";
import { UsuarioFormularioRequisicao } from "../models/usuario-formulario-requisicao.model";

@Injectable()
export class FormularioService {

    private url: string = `${environment.URL_API_ADM_FORM}/api/v1/formulario/prevNator`;

    constructor(private http: HttpClient) { }

    public buscarFormulario(requisicaoFormulario: UsuarioFormularioRequisicao): Observable<Formulario> {
        return this.http.post<Formulario>(this.url, requisicaoFormulario);
    }

    public buscarOpcoesFormulario(url:string): Observable<FormularioOpcao>{
        let urlLocal = `${this.url}/opcoes/${url}`;
        return this.http.post<FormularioOpcao>(urlLocal,null);
    }

}
