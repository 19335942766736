<header class="layout-v2-header layout-v2-fundo-roxo"
    *ngIf="estilo == null || (estilo?.opcaoCabecalho == 0
        && (estilo.exibeImg1Cabecalho == true || estilo.exibeImg2Cabecalho == true))">
    <div class="img1 img1-option0" *ngIf="estilo?.exibeImg1Cabecalho"></div>
    <div class="img2 img2-option0" *ngIf="estilo?.exibeImg2Cabecalho"></div>
</header>

<header class="layout-v2-header layout-v2-fundo-roxo layout-v2-header-option1"
    *ngIf="estilo?.opcaoCabecalho == 1 && estilo?.exibeImg1Cabecalho == true">
    <div class="img1"></div>
</header>
 
