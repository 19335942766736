import { Injectable } from "@angular/core";

@Injectable()
export class VideoService{

    public videosAssistidosForm: Map<string, boolean[]> = new Map();

    constructor(){}

    public addVideo(chave: string){
        if(!this.videosAssistidosForm.get(chave)){
            let valores: boolean[] = [false,false];
            this.videosAssistidosForm.set(chave,valores);
        }
    }

    public setVideoPlay(chave:string){
        let valores:boolean[] = [true,false];
        this.videosAssistidosForm.set(chave,valores);
    }

    public setVideoAssistido(chave: string){
        let valores:boolean[] = [true,true];
        this.videosAssistidosForm.set(chave,valores);
    }

    public listarVideos(){
        this.videosAssistidosForm.forEach((v,k) => {
            console.log("Video:")
            console.log(`${k} : ${v}`);
        });
    }

    public videoAssistido(chave:string):boolean{
        let valor = this.videosAssistidosForm.get(chave);
        if(valor != undefined){
            return valor[1];
        }
        return false;
    }

    public videoClicado(chave:string):boolean{
        let valor = this.videosAssistidosForm.get(chave);
        if(valor != undefined){
            return valor[0];
        }
        return false;
    }

}
