import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UsuarioFormularioRequisicaoIP } from "../models/usuario-formulario-requisicao-ip.model";
import { RespostaEnvio } from "../models/resposta-envio.model";
import { RespostaRetorno } from "../models/resposta-retorno.model";

@Injectable()
export class APICadFormService{

    private URL_API: string = `${environment.URL_API_ADM_FORM}/api/v1/usuario-formulario`;

    constructor(
        private http: HttpClient
    ){}

    public registrarRespostaPergunta(idUsuario: number, respostaPergunta: RespostaEnvio): Observable<RespostaRetorno>{
        let url = `${this.URL_API}/resposta-pergunta/${idUsuario}`;
        return this.http.post<RespostaRetorno>(url,respostaPergunta);
    }

    public salvarIpUsuario(requisicaoIP: UsuarioFormularioRequisicaoIP): Observable<UsuarioFormularioRequisicaoIP> {
        let url = `${this.URL_API}/salvaIpUsuario`;
        return this.http.post<UsuarioFormularioRequisicaoIP>(url, requisicaoIP);
    }

}
