import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ViewMensagemService{

    private exibirMensagem: Subject<string | null> = new Subject<string | null>();

    public solicitarAlertaMensagem(mensagem: string){
        this.exibirMensagem.next(mensagem);
    }

    public encerrarAlertaMensagem(){
        this.exibirMensagem.next(null);
    }

    public alertarMensagem(): Observable<string | null> {
        return this.exibirMensagem.asObservable();
    }

}
