import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LayoutV2Component } from "./layout-v2.component";
import { ComponentsModule } from "./components/components.module";
import { AssemblerModule } from "./assembler/assembler.module";

@NgModule({
    declarations: [
        LayoutV2Component
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        AssemblerModule
    ],
    exports: [
        LayoutV2Component
    ],
    providers: []
})
export class LayoutV2Module{

}
