import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LayoutV1Componet } from "./layout-v1.component";
import { CabecalhoModule } from "./components/cabecalho/cabecalho.module";
import { FooterModule } from "./components/footer/footer.module";
import { ContainerModule } from "./components/container/container.module";

@NgModule({
    declarations: [
        LayoutV1Componet
    ],
    imports: [
        CommonModule,
        CabecalhoModule,
        FooterModule,
        ContainerModule
    ],
    exports: [
        LayoutV1Componet
    ],
    providers: []
})
export class LayoutV1Module{

}
