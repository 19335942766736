import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class PassadorService{

    private passarPergunta: Subject<boolean> = new Subject<boolean>();

    constructor(){}

    public setPassador(valor: boolean){
        this.passarPergunta.next(valor);
    }

    public getPassador(): Observable<boolean>{
        return this.passarPergunta.asObservable();
    }

}
