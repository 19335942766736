import { Component, DoCheck, Input} from '@angular/core';
import { FormularioOpcao } from "src/app/models/formulario-opcao.model";

@Component({
    selector: 'layout-v1-cabecalho',
    templateUrl: './cabecalho.component.html',
    styleUrls: ['./cabecalho.component.scss'],
})
export class CabecalhoComponent implements DoCheck {

    @Input()
    public estilo!: FormularioOpcao | undefined;

    constructor() {}

    ngDoCheck(): void {
        this.definirImagens();
    }

    private definirImagens(){

        const img1 = document.querySelector("#img1Cab") as HTMLDivElement;
        const img2 = document.querySelector("#img2Cab") as HTMLDivElement;

        if(this.estilo != null) {
            if(img1 && this.estilo.exibeImg1Cabecalho == true) {
                if(this.estilo.imagemImg1Base64){
                    img1.style.backgroundImage = `url("data:image/png;base64,${this.estilo.imagemImg1Base64}")`;
                }else{
                    img1.style.backgroundImage = "url('/assets/imgs/logo1.png')";
                }
            }
            if(img2 && this.estilo.exibeImg2Cabecalho == true) {
                if(this.estilo.imagemImg2Base64){
                    img2.style.backgroundImage = `url("data:image/png;base64,${this.estilo.imagemImg2Base64}")`;
                }else{
                    img2.style.backgroundImage = "url('/assets/imgs/logo2.png')";
                }
            }

        }else{
            if(img1) img1.style.backgroundImage = "url('/assets/imgs/logo1.png')";
            if(img2) img2.style.backgroundImage = "url('/assets/imgs/logo2.png')";
        }

    }

}
