<div class="layout-v2-div" [id]="getIdButton()" (click)="onClick()" *ngIf="type == '0'"
    [ngClass]="{'layout-v2-div-not-selection': !isSelection && !ownStyle, 
        'layout-v2-div-selection' : isSelection && !ownStyle, 'height-compacto': compacto}">

    <span class="letter font-button" *ngIf="letter.length > 0 && showLetter"
        [ngClass]="{'backgroundLetter-not-selection': !isSelection && !ownStyle,
            'backgroundLetter-selection': isSelection && !ownStyle,
            'colorTextLetter-not-selection': !isSelection && !ownStyle,
            'colorTextLetter-selection': isSelection && !ownStyle}">{{letter | uppercase}}</span>

    <mat-icon [ngClass]="{'colorIcon-not-selection': !isSelection,
            'colorIcon-selection': isSelection}" *ngIf="styleButton?.exibirIconeEsquerdo"
        class="material-symbols-outlined" style="margin-right: 20px;">{{styleButton?.iconeEsquerdo}}</mat-icon>

    <span class="title font-button"
        [ngClass]="{'colorTitle-not-selection': !isSelection && !ownStyle,
                    'colorTitle-selection': isSelection && !ownStyle}">{{title}}</span>

    <mat-icon [ngClass]="{'colorIcon-not-selection': !isSelection,
    'colorIcon-selection': isSelection}" *ngIf="styleButton?.exibirIconeDireito"
        style="position: absolute; right: 15px;" class="material-symbols-outlined">{{styleButton?.iconeDireito}}</mat-icon>

</div>

<div class="layout-v2-button-voltar" *ngIf="type == '1'" (click)="onClick()">
    <span class="font-button back-button">
        <mat-icon class="material-symbols-outlined">chevron_left</mat-icon>Voltar
    </span>
</div>

<div class="layout-v2-div layout-v2-button-final" *ngIf="type == '2'" (click)="onClick()">
    <div class="icon"></div>
    <span class="title font-button">Simular Agora!</span>
    <mat-icon class="material-symbols-outlined icon-right-btn-whatsApp">chevron_right</mat-icon>
</div>

<div class="layout-v2-div" [id]="getIdButton()" (click)="onClick()" *ngIf="type == '3'"
    [ngClass]="{'layout-v2-div-not-selection': !isSelection, 'layout-v2-div-selection' : isSelection, 
                'cor-2': trocarCor}">

    <mat-icon [ngClass]="{'colorIcon-not-selection': !isSelection,
            'colorIcon-selection': isSelection}" *ngIf="styleButton?.exibirIconeEsquerdo"
        class="material-symbols-outlined" style="margin-right: 20px;">{{styleButton?.iconeEsquerdo}}</mat-icon>

    <span class="title font-button"
        [ngClass]="{'colorTitle-not-selection': !isSelection,
                    'colorTitle-selection': isSelection}">{{title}}</span>

    <mat-icon [ngClass]="{'colorIcon-not-selection': !isSelection,
    'colorIcon-selection': isSelection}" *ngIf="styleButton?.exibirIconeDireito"
        style="position: absolute; right: 15px;" class="material-symbols-outlined">{{styleButton?.iconeDireito}}</mat-icon>

</div>
