import { Component, Input } from "@angular/core";

@Component({
    selector: 'layout-v2-wizzard',
    templateUrl: './wizzard.component.html',
    styleUrls: ['./wizzard.component.scss']
})
export class WizzardComponent{

    @Input()
    public steps: number | undefined = 0;

    @Input()
    public currentStep: number | undefined = 0;

    constructor(){}

    public getSteps(): number[] {
        let steps = [];
        for(let i=0; i < (this.steps as number); i++){
            steps[i] = (i+1);
        }
        return steps;
    }

    public getCurrentStep(): number{
        return this.currentStep as number;
    }



}
