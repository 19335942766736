<div class="container" id="container">

    <div class="painel-pergunta">

        <div class="pergunta slide" *ngFor="let p of perguntas; let i = index">

            <div>
                <layout-v1-campo-formulario [pergunta]="p" [mostrarBtnVoltar]="i > 0"
                    [estilo]="formulario.estilo"
                    (resposta)="onResponder($event)" (voltarPergunta)="onVoltar()"></layout-v1-campo-formulario>
            </div>

            <div *ngIf="formulario.estilo?.mostrarBarraProgresso">

                <layou-v1-barra-progresso
                    [totalIndice]="p.totalIndiceBarraProgresso"
                    [indiceAtual]="p.indiceBarraProgresso"
                    [corBarra]="formulario.estilo?.corBarraProgresso"
                    [corPasso]="formulario.estilo?.corPassoProgresso"></layou-v1-barra-progresso>

            </div>

        </div>

    </div>

</div>
