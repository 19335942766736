import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Formulario } from "src/app/models/formulario.model";
import { Pergunta } from "src/app/models/pergunta.model";
import { RespostaUsuario } from "src/app/models/resposta-usuario.model";

@Component({
    selector: 'layout-v2',
    templateUrl: './layout-v2.component.html',
    styleUrls: ['./layout-v2.component.scss']
})
export class LayoutV2Component implements DoCheck{

    @Input()
    public formulario!: Formulario;

    @Input()
    public perguntas!: Pergunta[];

    @Output()
    public eventoResponder:EventEmitter<RespostaUsuario> = new EventEmitter();

    @Output()
    public eventoVoltar:EventEmitter<void> = new EventEmitter();

    @Output()
    public eventoVideo:EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("boxContainer")
    private boxContainer!: ElementRef<HTMLDivElement>;

    public showContent: boolean = false;

    constructor(){
        let body = document.querySelector("body") as HTMLBodyElement;
        body.style.backgroundColor = "#3D3B80";
    }

    ngDoCheck(): void {
        if(this.formulario && !this.getExibiSplashscreen()) {
            let body = document.querySelector("body") as HTMLBodyElement;
            body.style.backgroundColor = "transparent";
        }
        if(!this.boxContainer) return;
        let heightDevice = window.screen.height;
        if(heightDevice < 802) this.boxContainer.nativeElement.style.marginBottom = "48px";
        if(heightDevice >= 803 && heightDevice < 850) {
            let value = 850 - heightDevice;
            this.boxContainer.nativeElement.style.marginBottom = `${value}px`;
        }
    }

    public onResponder(resposta: RespostaUsuario){
        this.eventoResponder.emit(resposta);
    }

    public onVoltar(){
        this.eventoVoltar.emit();
    }

    public stopAnimation(){
        this.showContent = true;
        let body = document.querySelector("body") as HTMLBodyElement;
        body.style.backgroundColor = "transparent";
    }

    public getExibiSplashscreen(): boolean{
        if(this.formulario.estilo?.extras) return this.formulario.estilo?.extras.exibirSplashscreen as boolean; 
        return false;
    }

    public onEventVideo(dado: object) {
        this.eventoVideo.emit(dado);
    }

}
