import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: 'layou-v1-barra-progresso',
    templateUrl: './barra-progresso.component.html',
    styleUrls: ['./barra-progresso.component.scss']
})
export class BarraProgressoComponent implements OnChanges{

    @Input()
    public indiceAtual: number | undefined = 0;

    @Input()
    public totalIndice: number | undefined = 0;

    @Input()
    public corBarra: string | undefined = "#000000";

    @Input()
    public corPasso: string | undefined = "#3B397F";

    public porcentagemBarra:string = "0%";

    ngOnChanges(changes: SimpleChanges): void {
        this.indiceAtual = this.indiceAtual as number;
        this.totalIndice = this.totalIndice as number;
        if(this.totalIndice > 0){
            let porcentagem = (this.indiceAtual / this.totalIndice)  * 100;
            this.porcentagemBarra = `${porcentagem}%`;
        }
    }

    public getCorBarra():string{
        return `background-color:${this.corBarra}`;
    }

}
