import { NgModule } from "@angular/core";
import { CabecalhoComponent } from "./cabecalho.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CabecalhoComponent],
    imports: [
        CommonModule
    ],
    exports: [
        CabecalhoComponent
    ],
    providers: []
})
export class CabecalhoModule{

}
