import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Link } from "../models/link.model";


@Injectable()
export class EnvioAPIService {

    private respostas: Map<string, string | string[]> = new Map();

    constructor(private http: HttpClient,) {}

    public addResposta(campo: string, resposta: string | string[]) {
        this.respostas.set(campo, resposta);
    }

    public setRespostas(respostas: Map<string, string | string[]>) {
        this.respostas = respostas;
    }

    public getRespostas(): Map<string, string | string[]> {
        return this.respostas;
    }

    public enviarRespostasAPI(metodo: string, url: string, corpo: any):Observable<Link>{

        if(metodo == "GET"){
            return this.http.get<Link>(url);
        }

        if(metodo == "PUT"){
            return this.http.put<Link>(url,corpo);
        }

        if(metodo == "DELETE"){
            return this.http.delete<any>(url);
        }

        if(metodo == "PATCH"){
            return this.http.patch<Link>(url,corpo);
        }

        return this.http.post<Link>(url,corpo);

    }

}
