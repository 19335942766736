import { Injectable } from "@angular/core";

@Injectable()
export class IdSessaoService{

    private nomeId = "idSessao";

    public recuperarIdSessao(): string | null{
        let id = localStorage.getItem(this.nomeId);
        return id;
    }

    public gerarIdSessao(){

        const caracteres ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#@-*$&!';

        let id = "";

        for(let i = 0; i < 30; i++){
            id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }

        localStorage.setItem(this.nomeId,id);

    }

}
