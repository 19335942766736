import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ControllerComponent } from "./controller.component";
import { ViewModule } from "../view/view.module";
import { ServicesModule } from "./services/services.module";
import { RouterModule } from "@angular/router";


@NgModule({
    declarations: [
        ControllerComponent
    ],
    imports: [
        CommonModule,
        ViewModule,
        RouterModule,
        ServicesModule
    ],
    exports: [
        //ControllerComponent
    ]
})
export class ControllerModule{

}
